import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { App } from './App'
import { initTracking } from '@utils/tracking'
import './index.css'
import '@strise/system/src/index.css'
import '@strise/midgard/src/tailwind.css'
import { getBrowserGlobals } from '@strise/system'
import { enableMocking } from './mocks'

initTracking()

const element = getBrowserGlobals()?.document.getElementById('root')
if (element) {
  const root = createRoot(element)
  enableMocking().then(() => {
    root.render(<App />)
  })
}
