import * as Types from './graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const CountryFragmentDoc = gql`
    fragment Country on Country {
  id
  name
  englishName
  isoAlpha2Code
}
    `;
export const RoleTitleFragmentDoc = gql`
    fragment RoleTitle on RoleTitle {
  id
  name
  country {
    ...Country
  }
}
    ${CountryFragmentDoc}`;
export const EntityAccessFragmentDoc = gql`
    fragment EntityAccess on EntityAccess {
  hasAccess
  description
}
    `;
export const LifeStatusFragmentDoc = gql`
    fragment LifeStatus on CompanyLifeStatus {
  isActive
  date
  lifeStatus
}
    `;
export const TableLabelBaseFragmentDoc = gql`
    fragment TableLabelBase on TableLabelLike {
  isActive
  ... on TableLabelString {
    content
  }
}
    `;
export const TableRowValueReviewMetaFragmentDoc = gql`
    fragment TableRowValueReviewMeta on TableRowValueReviewMeta {
  warning
  reason
}
    `;
export const TableRowValueBaseFragmentDoc = gql`
    fragment TableRowValueBase on TableRowValueLike {
  link
  tooltip
  copyValue
  iconId
  reviewMeta {
    ...TableRowValueReviewMeta
  }
  ... on TableRowValueString {
    content
  }
  ... on TableRowValueLabeledString {
    label
    content
  }
}
    ${TableRowValueReviewMetaFragmentDoc}`;
export const TableRowSectionBaseFragmentDoc = gql`
    fragment TableRowSectionBase on TableRowSection {
  label {
    ...TableLabelBase
  }
  paginationThreshold
  values {
    ...TableRowValueBase
  }
}
    ${TableLabelBaseFragmentDoc}
${TableRowValueBaseFragmentDoc}`;
export const TableRowBaseFragmentDoc = gql`
    fragment TableRowBase on TableRow {
  key
  label {
    ...TableLabelBase
  }
  kind
  paginationThreshold
  sections {
    ...TableRowSectionBase
  }
}
    ${TableLabelBaseFragmentDoc}
${TableRowSectionBaseFragmentDoc}`;
export const DataSourceFragmentDoc = gql`
    fragment DataSource on DataSource {
  name
  link
  lastUpdatedAt
}
    `;
export const TableBaseFragmentDoc = gql`
    fragment TableBase on Table {
  title
  description
  rows {
    ...TableRowBase
  }
  dataSources {
    ...DataSource
  }
}
    ${TableRowBaseFragmentDoc}
${DataSourceFragmentDoc}`;
export const CompanyBaseFragmentDoc = gql`
    fragment CompanyBase on Company {
  id
  name
  registerCountry
  lifeStatus {
    ...LifeStatus
  }
  keyMeta {
    ...TableBase
  }
  access {
    ...EntityAccess
  }
  isGlobalCompany
}
    ${LifeStatusFragmentDoc}
${TableBaseFragmentDoc}
${EntityAccessFragmentDoc}`;
export const GlobalCompanyBaseFragmentDoc = gql`
    fragment GlobalCompanyBase on GlobalCompany {
  id
  name
  registerCountry
  primaryCountry {
    ...Country
  }
  lifeStatus {
    ...LifeStatus
  }
  keyMeta {
    ...TableBase
  }
  access {
    ...EntityAccess
  }
}
    ${CountryFragmentDoc}
${LifeStatusFragmentDoc}
${TableBaseFragmentDoc}
${EntityAccessFragmentDoc}`;
export const AddressFragmentDoc = gql`
    fragment Address on Address {
  addressLine
  city
  zipCode
  country
}
    `;
export const PersonBaseFragmentDoc = gql`
    fragment PersonBase on Person {
  id
  name
  nationality
  primaryCountry {
    ...Country
  }
  keyMeta {
    ...TableBase
  }
  access {
    ...EntityAccess
  }
  birthDate
  address {
    ...Address
  }
  isGlobalPerson
}
    ${CountryFragmentDoc}
${TableBaseFragmentDoc}
${EntityAccessFragmentDoc}
${AddressFragmentDoc}`;
export const GlobalPersonBaseFragmentDoc = gql`
    fragment GlobalPersonBase on GlobalPerson {
  id
  name
  pep
  primaryCountry {
    ...Country
  }
  keyMeta {
    ...TableBase
  }
  access {
    ...EntityAccess
  }
  birthDate
  address {
    ...Address
  }
}
    ${CountryFragmentDoc}
${TableBaseFragmentDoc}
${EntityAccessFragmentDoc}
${AddressFragmentDoc}`;
export const PrivatePersonBaseFragmentDoc = gql`
    fragment PrivatePersonBase on PrivatePerson {
  id
  name
  keyMeta {
    ...TableBase
  }
  access {
    ...EntityAccess
  }
}
    ${TableBaseFragmentDoc}
${EntityAccessFragmentDoc}`;
export const BaseEntityLikeFragmentDoc = gql`
    fragment BaseEntityLike on EntityLike {
  id
  name
  access {
    ...EntityAccess
  }
  ... on Company {
    ...CompanyBase
  }
  ... on GlobalCompany {
    ...GlobalCompanyBase
  }
  ... on Person {
    ...PersonBase
  }
  ... on GlobalPerson {
    ...GlobalPersonBase
  }
  ... on PrivatePerson {
    ...PrivatePersonBase
  }
}
    ${EntityAccessFragmentDoc}
${CompanyBaseFragmentDoc}
${GlobalCompanyBaseFragmentDoc}
${PersonBaseFragmentDoc}
${GlobalPersonBaseFragmentDoc}
${PrivatePersonBaseFragmentDoc}`;
export const TeamCompanyStatusFragmentDoc = gql`
    fragment TeamCompanyStatus on TeamCompanyStatus {
  status
  previousStatus
  modifiedAt
  modifiedBy {
    id
    name
  }
  modifiedByKind
}
    `;
export const CompanyTagFragmentDoc = gql`
    fragment CompanyTag on Sticker {
  id
  name
}
    `;
export const CompanyTagConnectionEdgeFragmentDoc = gql`
    fragment CompanyTagConnectionEdge on CompanyStickerConnectionEdge {
  node {
    id
    ...CompanyTag
  }
}
    ${CompanyTagFragmentDoc}`;
export const CompanyTagConnectionFragmentDoc = gql`
    fragment CompanyTagConnection on CompanyStickerConnection {
  key
  edges {
    ...CompanyTagConnectionEdge
  }
}
    ${CompanyTagConnectionEdgeFragmentDoc}`;
export const CompanyTagsFragmentDoc = gql`
    fragment CompanyTags on Company {
  id
  tags: stickers(team: $team) {
    ...CompanyTagConnection
  }
}
    ${CompanyTagConnectionFragmentDoc}`;
export const SimpleUserFragmentDoc = gql`
    fragment SimpleUser on SimpleUser {
  id
  name
  avatar
  email
}
    `;
export const CompanyUserConnectionEdgeFragmentDoc = gql`
    fragment CompanyUserConnectionEdge on CompanyUserConnectionEdge {
  node {
    id
    ...SimpleUser
  }
}
    ${SimpleUserFragmentDoc}`;
export const CompanyUserConnectionFragmentDoc = gql`
    fragment CompanyUserConnection on CompanyUserConnection {
  edges {
    ...CompanyUserConnectionEdge
  }
}
    ${CompanyUserConnectionEdgeFragmentDoc}`;
export const LegalFormFragmentDoc = gql`
    fragment LegalForm on LegalForm {
  id
  name
  code
  countryV2
  kind
  hasShares
  hasBoard
  hasOwner
  hasBeneficialOwners
  hasPartners
  hasNorwegianSoleTraderCreditData
  hasCollateral
  hasShareClasses
  hasCreditReport
}
    `;
export const CompanyMetaFragmentDoc = gql`
    fragment CompanyMeta on Company {
  id
  ...CompanyBase
  statusV2(team: $team, portfolio: $portfolio) {
    ...TeamCompanyStatus
  }
  ...CompanyTags
  assignee(team: $team) {
    ...CompanyUserConnection
  }
  legalForm {
    ...LegalForm
  }
  showOwnerChart
}
    ${CompanyBaseFragmentDoc}
${TeamCompanyStatusFragmentDoc}
${CompanyTagsFragmentDoc}
${CompanyUserConnectionFragmentDoc}
${LegalFormFragmentDoc}`;
export const SimpleCompanyBaseFragmentDoc = gql`
    fragment SimpleCompanyBase on SimpleCompany {
  id
  name
  lifeStatus {
    ...LifeStatus
  }
  access {
    ...EntityAccess
  }
}
    ${LifeStatusFragmentDoc}
${EntityAccessFragmentDoc}`;
export const SimpleCompanyMetaFragmentDoc = gql`
    fragment SimpleCompanyMeta on SimpleCompany {
  id
  ...SimpleCompanyBase
  organizationNumber
  registerCountry
  primaryCountry {
    id
    ...Country
  }
}
    ${SimpleCompanyBaseFragmentDoc}
${CountryFragmentDoc}`;
export const GlobalCompanyMetaFragmentDoc = gql`
    fragment GlobalCompanyMeta on GlobalCompany {
  id
  ...GlobalCompanyBase
}
    ${GlobalCompanyBaseFragmentDoc}`;
export const PersonMetaFragmentDoc = gql`
    fragment PersonMeta on Person {
  id
  ...PersonBase
}
    ${PersonBaseFragmentDoc}`;
export const GlobalPersonMetaFragmentDoc = gql`
    fragment GlobalPersonMeta on GlobalPerson {
  id
  ...GlobalPersonBase
}
    ${GlobalPersonBaseFragmentDoc}`;
export const PrivatePersonMetaFragmentDoc = gql`
    fragment PrivatePersonMeta on PrivatePerson {
  id
  ...PrivatePersonBase
}
    ${PrivatePersonBaseFragmentDoc}`;
export const EntityLikeMetaFragmentDoc = gql`
    fragment EntityLikeMeta on EntityLike {
  id
  ...BaseEntityLike
  ... on Company {
    ...CompanyMeta
  }
  ... on SimpleCompany {
    ...SimpleCompanyMeta
  }
  ... on GlobalCompany {
    ...GlobalCompanyMeta
  }
  ... on Person {
    ...PersonMeta
  }
  ... on GlobalPerson {
    ...GlobalPersonMeta
  }
  ... on PrivatePerson {
    ...PrivatePersonMeta
  }
}
    ${BaseEntityLikeFragmentDoc}
${CompanyMetaFragmentDoc}
${SimpleCompanyMetaFragmentDoc}
${GlobalCompanyMetaFragmentDoc}
${PersonMetaFragmentDoc}
${GlobalPersonMetaFragmentDoc}
${PrivatePersonMetaFragmentDoc}`;
export const FinancialsRowFragmentDoc = gql`
    fragment FinancialsRow on FinancialsRow {
  values
  afterUnderline
  fieldInfo {
    id
  }
}
    `;
export const FinancialsSectionFragmentDoc = gql`
    fragment FinancialsSection on FinancialsSection {
  key
  name
  rows {
    ...FinancialsRow
  }
}
    ${FinancialsRowFragmentDoc}`;
export const FinancialsMetaFragmentDoc = gql`
    fragment FinancialsMeta on FinancialStatementMeta {
  year
  periodTitle
  currency
  notes
  auditorComment
  auditorClarification
}
    `;
export const FinancialsFragmentDoc = gql`
    fragment Financials on Financials {
  sections {
    ...FinancialsSection
  }
  meta {
    ...FinancialsMeta
  }
  operatingRevenues
  compare {
    ...FinancialsSection
  }
}
    ${FinancialsSectionFragmentDoc}
${FinancialsMetaFragmentDoc}`;
export const FinancialStatementsFragmentDoc = gql`
    fragment FinancialStatements on Company {
  id
  financials(size: 5, consolidated: false) {
    ...Financials
  }
  consolidatedFinancials: financials(size: 5, consolidated: true) {
    ...Financials
  }
}
    ${FinancialsFragmentDoc}`;
export const CompanyFinancialsMetaFragmentDoc = gql`
    fragment CompanyFinancialsMeta on Company {
  id
  metaFinancials: financials(size: 1, consolidated: false) {
    meta {
      year
      currency
    }
    operatingRevenues
  }
  metaConsolidatedFinancials: financials(size: 1, consolidated: true) {
    meta {
      year
      currency
    }
    operatingRevenues
  }
}
    `;
export const FinancialFieldInfoFragmentDoc = gql`
    fragment FinancialFieldInfo on FinancialFieldInfo {
  id
  name
  kind
  renderKind
  country
  isExpense
}
    `;
export const ApplicationSettingsFragmentDoc = gql`
    fragment ApplicationSettings on ApplicationSettings {
  displayLanguage
  sidePanelFinancialHighlightsSelector
  opportunityFinancialHighlightsSelector
  opportunityFinancialHighlightCount
  timeZone
  useCaseKinds
  positionKind
}
    `;
export const BaseRateLimitFragmentDoc = gql`
    fragment BaseRateLimit on RateLimit {
  id
  used
  quota
  resetAt
}
    `;
export const CounterPartyFragmentDoc = gql`
    fragment CounterParty on CounterParty {
  id
  entity {
    ...BaseEntityLike
  }
  counterPartyEntity {
    ...BaseEntityLike
  }
  nature
  comment
  createdBy {
    ...SimpleUser
  }
  lastModified
}
    ${BaseEntityLikeFragmentDoc}
${SimpleUserFragmentDoc}`;
export const CountyCourtJudgementFragmentDoc = gql`
    fragment CountyCourtJudgement on CountyCourtJudgement {
  amount
  caseNumber
  court
  currencyCode
  date
  incomingRecordDetails
  isExactMatch
  paidDate
  status
}
    `;
export const CountyCourtJudgementsReportFragmentDoc = gql`
    fragment CountyCourtJudgementsReport on CountyCourtJudgementsReport {
  date
  judgements {
    ...CountyCourtJudgement
  }
}
    ${CountyCourtJudgementFragmentDoc}`;
export const TeamFragmentDoc = gql`
    fragment Team on Team {
  id
  name
  customer {
    id
  }
  portfolios {
    edges {
      node {
        id
      }
    }
  }
}
    `;
export const TeamEdgeFragmentDoc = gql`
    fragment TeamEdge on UserTeamConnectionEdge {
  node {
    id
    ...Team
  }
  role {
    id
    name
  }
  status
}
    ${TeamFragmentDoc}`;
export const CurrentUserFragmentDoc = gql`
    fragment CurrentUser on User {
  id
  name
  pseudonymizedName
  email
  avatar
  teams {
    edges {
      ...TeamEdge
    }
  }
}
    ${TeamEdgeFragmentDoc}`;
export const EntityDataSourcesFragmentDoc = gql`
    fragment EntityDataSources on EntityDataSources {
  baseInfo {
    ...DataSource
  }
  financials {
    ...DataSource
  }
  collateral {
    ...DataSource
  }
  realEstate {
    ...DataSource
  }
  credit {
    ...DataSource
  }
  shareholders {
    ...DataSource
  }
  roles {
    ...DataSource
  }
  sanctions {
    ...DataSource
  }
}
    ${DataSourceFragmentDoc}`;
export const SanctionInfoFragmentDoc = gql`
    fragment SanctionInfo on SanctionInfoV2 {
  sanctionSources
  lastUpdated
  sanctionedSince
  sourceLinks
  sourceJson
  sanctionedBy
}
    `;
export const EntitySanctionInfoFragmentDoc = gql`
    fragment EntitySanctionInfo on EntityLike {
  id
  name
  ... on Company {
    sanctionInfo(team: $teamId) {
      ...SanctionInfo
    }
  }
  ... on GlobalCompany {
    sanctionInfo(team: $teamId) {
      ...SanctionInfo
    }
  }
  ... on Person {
    sanctionInfo(team: $teamId) {
      ...SanctionInfo
    }
  }
  ... on GlobalPerson {
    sanctionInfo(team: $teamId) {
      ...SanctionInfo
    }
  }
  ... on PrivatePerson {
    sanctionInfo(team: $teamId) {
      ...SanctionInfo
    }
  }
}
    ${SanctionInfoFragmentDoc}`;
export const BaseEventFragmentDoc = gql`
    fragment BaseEvent on Event {
  id
  title
  published
  publisher
  approximateSimilarCount(clusterScore: 0.5)
  behindPaywall
  images {
    url
  }
  companyEventKinds
  flagSeverity
}
    `;
export const BaseEventWithSummaryFragmentDoc = gql`
    fragment BaseEventWithSummary on Event {
  ...BaseEvent
  summary {
    text
  }
}
    ${BaseEventFragmentDoc}`;
export const EventFragmentDoc = gql`
    fragment Event on Event {
  ...BaseEventWithSummary
  liked(portfolio: $portfolio, team: $team, company: $entity)
}
    ${BaseEventWithSummaryFragmentDoc}`;
export const EventSettingsFragmentDoc = gql`
    fragment EventSettings on EventFilter {
  time {
    since
  }
  topics
  sources {
    eventTypes
  }
}
    `;
export const ListViewCompanySettingsFragmentDoc = gql`
    fragment ListViewCompanySettings on CompanyFilter {
  statuses
  employees {
    from
    to
  }
  locations
  revenue {
    from
    to
  }
  assignees
  stickers
  severity
  countries
}
    `;
export const LocationFragmentDoc = gql`
    fragment Location on Location {
  id
  name
  description
  documentationUrl
  countries: countriesV2 {
    id
    ...Country
  }
}
    ${CountryFragmentDoc}`;
export const OpportunitySettingsFragmentDoc = gql`
    fragment OpportunitySettings on OpportunitySettings {
  employees {
    from
    to
  }
  industries
  locations {
    locations
    polygon {
      latitude
      longitude
    }
  }
  assignees
  stickers
  requireNoAccountant
  accountants
  financials {
    valueFilters {
      id
      from
      to
    }
  }
  collateralCreditors
  realEstateCollateralCreditors
  inception {
    moreThan
    lessThan
  }
  legalForms
  flagKinds
}
    `;
export const RealEstateFragmentDoc = gql`
    fragment RealEstate on RealEstate {
  info
  warnings {
    title
    description
    date
  }
  documents {
    id
    title
    tooltip
    date
    amount
    currency
    amountTooltip
    creditors {
      ...BaseEntityLike
    }
    otherCreditorNames
  }
  date
}
    ${BaseEntityLikeFragmentDoc}`;
export const ReviewCompanySettingsFragmentDoc = gql`
    fragment ReviewCompanySettings on CompanyFilter {
  statuses
  assignees
  stickers
}
    `;
export const ReviewSettingsFragmentDoc = gql`
    fragment ReviewSettings on ReviewSettings {
  companies {
    ...ReviewCompanySettings
  }
}
    ${ReviewCompanySettingsFragmentDoc}`;
export const RiskSummaryFragmentDoc = gql`
    fragment RiskSummary on RiskSummary {
  risks {
    label
    reason
    level
    kind
  }
}
    `;
export const RegionFragmentDoc = gql`
    fragment Region on Location {
  id
  name
}
    `;
export const IndustryFragmentDoc = gql`
    fragment Industry on Industry {
  id
  name
  description
  country
}
    `;
export const FlagKindInfoFragmentDoc = gql`
    fragment FlagKindInfo on FlagKindInfo {
  id
  name
  countries
}
    `;
export const UserGrowSettingsFragmentDoc = gql`
    fragment UserGrowSettings on UserGrowSettings {
  assignees {
    ...SimpleUser
  }
  tags {
    ...CompanyTag
  }
  locations {
    ...Region
  }
  coordinates {
    latitude
    longitude
  }
  industries {
    ...Industry
  }
  employees {
    from
    to
  }
  inception {
    moreThan
    lessThan
  }
  accountants {
    ...BaseEntityLike
  }
  requireNoAccountant
  collateralCreditors {
    ...BaseEntityLike
  }
  realEstateCollateralCreditors {
    ...BaseEntityLike
  }
  legalForms {
    ...LegalForm
  }
  flagKinds {
    ...FlagKindInfo
  }
  financialFieldsHighlighted
  financialHighlightCount
  financials {
    id
    from
    to
  }
}
    ${SimpleUserFragmentDoc}
${CompanyTagFragmentDoc}
${RegionFragmentDoc}
${IndustryFragmentDoc}
${BaseEntityLikeFragmentDoc}
${LegalFormFragmentDoc}
${FlagKindInfoFragmentDoc}`;
export const TopicFragmentDoc = gql`
    fragment Topic on Topic {
  id
  name
}
    `;
export const UserSidepanelSettingsFragmentDoc = gql`
    fragment UserSidepanelSettings on UserSidepanelSettings {
  topics {
    ...Topic
  }
  sources
  financialFieldsHighlighted
}
    ${TopicFragmentDoc}`;
export const UserReviewSettingsFragmentDoc = gql`
    fragment UserReviewSettings on UserReviewSettings {
  statuses
  assignees {
    ...SimpleUser
  }
  tags {
    ...CompanyTag
  }
}
    ${SimpleUserFragmentDoc}
${CompanyTagFragmentDoc}`;
export const UserPortfolioSettingsFragmentDoc = gql`
    fragment UserPortfolioSettings on UserPortfolioSettings {
  countries
  flagSeverities
  statuses
  assignees {
    ...SimpleUser
  }
  tags {
    ...CompanyTag
  }
  employees {
    from
    to
  }
  revenue {
    from
    to
  }
  locations {
    ...Region
  }
}
    ${SimpleUserFragmentDoc}
${CompanyTagFragmentDoc}
${RegionFragmentDoc}`;
export const UserSettingsFragmentDoc = gql`
    fragment UserSettings on UserSettings {
  generalTermsAccepted
  privacyTermsAccepted
  displayLanguage
  timeZone
  useCaseKinds
  positionKind
  grow {
    ...UserGrowSettings
  }
  sidepanel {
    ...UserSidepanelSettings
  }
  review {
    ...UserReviewSettings
  }
  portfolio {
    ...UserPortfolioSettings
  }
}
    ${UserGrowSettingsFragmentDoc}
${UserSidepanelSettingsFragmentDoc}
${UserReviewSettingsFragmentDoc}
${UserPortfolioSettingsFragmentDoc}`;
export const FeatureCategoryFragmentDoc = gql`
    fragment FeatureCategory on FeatureCategory {
  name
  kind
}
    `;
export const FeatureFragmentDoc = gql`
    fragment Feature on Feature {
  name
  kind
  category {
    ...FeatureCategory
  }
  isAdminOnly
  isBeta
  isEnabled
  isEditable
  requiresIntegration
  requiredIntegration @include(if: $includeIntegration)
  description
}
    ${FeatureCategoryFragmentDoc}`;
export const FeaturesFragmentDoc = gql`
    fragment Features on Features {
  features(
    includeFeatureCategories: $includeFeatureCategories
    excludeFeatureCategories: $excludeFeatureCategories
  ) {
    ...Feature
  }
  enabledContentLanguages
}
    ${FeatureFragmentDoc}`;
export const SettingsFragmentDoc = gql`
    fragment Settings on User {
  id
  userSettings(team: $team) {
    ...UserSettings
  }
  features: featuresV2(team: $team) {
    ...Features
  }
}
    ${UserSettingsFragmentDoc}
${FeaturesFragmentDoc}`;
export const ShareholderFragmentDoc = gql`
    fragment Shareholder on Shareholder {
  ... on Company {
    id
    ...CompanyMeta
    numOwnerships(from: 0)
  }
  ... on GlobalCompany {
    id
    ...GlobalCompanyMeta
    numOwnerships(from: 0)
  }
}
    ${CompanyMetaFragmentDoc}
${GlobalCompanyMetaFragmentDoc}`;
export const AdverseFlagFragmentDoc = gql`
    fragment AdverseFlag on AdverseFlag {
  id
  date
  reason
  severity
  entity {
    ...BaseEntityLike
  }
  event {
    id
  }
}
    ${BaseEntityLikeFragmentDoc}`;
export const EntityFlagsFragmentDoc = gql`
    fragment EntityFlags on EntityLike {
  id
  flags {
    edges {
      node {
        id
        ...AdverseFlag
      }
    }
  }
}
    ${AdverseFlagFragmentDoc}`;
export const TableLabelFragmentDoc = gql`
    fragment TableLabel on TableLabelLike {
  isActive
  ... on TableLabelString {
    content
  }
  ... on TableLabelEntity {
    entity {
      ...BaseEntityLike
      ...EntityFlags @include(if: $withFlags)
    }
    description
  }
  ... on TableLabelRole {
    name
    roleTitleId
  }
  ... on TableLabelEntityRole {
    entity {
      ...BaseEntityLike
    }
  }
}
    ${BaseEntityLikeFragmentDoc}
${EntityFlagsFragmentDoc}`;
export const PepRoleFragmentDoc = gql`
    fragment PepRole on PepRole {
  active
  roleCategory
  countryOfJurisdiction
  localizedRoleDescription
  from
  to
}
    `;
export const PepInfoFragmentDoc = gql`
    fragment PepInfo on PepInfo {
  name
  pep
  rca
  address {
    ...Address
  }
  roles {
    ...PepRole
  }
  relations {
    relationName
    from
    relationDescription
    relatedPep {
      name
      pep
      rca
      address {
        ...Address
      }
      roles {
        ...PepRole
      }
    }
  }
}
    ${AddressFragmentDoc}
${PepRoleFragmentDoc}`;
export const BeneficialOwnerMetaFragmentDoc = gql`
    fragment BeneficialOwnerMeta on BeneficialOwnerMeta {
  isAlternative
  reason
  familyRelationships {
    person {
      ...BaseEntityLike
    }
    relationships
  }
  votingPowerIncludingFamily
  meta {
    name
    value
  }
}
    ${BaseEntityLikeFragmentDoc}`;
export const CustomMetaFragmentDoc = gql`
    fragment CustomMeta on CustomMeta {
  createdBy {
    __typename
    pseudonymizedName
    id
    name
  }
  createdAt
  lastModifiedAt
  comment
  hidden
  lastModifiedBy {
    __typename
    pseudonymizedName
    id
    name
  }
}
    `;
export const TimespanFragmentDoc = gql`
    fragment Timespan on Timespan {
  from
  to
  yearsBetween
  monthsBetween
}
    `;
export const RoleMetaFragmentDoc = gql`
    fragment RoleMeta on RoleMeta {
  roleTitle
  roleTitleDescription
  roleTitleId
  isActive
  customMeta {
    ...CustomMeta
  }
  period {
    ...Timespan
  }
}
    ${CustomMetaFragmentDoc}
${TimespanFragmentDoc}`;
export const RegisterFragmentDoc = gql`
    fragment Register on Register {
  name
  date
  statusDescription
  isRegistered
  registerId
  kind
  event {
    id
  }
}
    `;
export const CollateralFragmentDoc = gql`
    fragment Collateral on Collateral {
  creditor {
    id
    ...BaseEntityLike
  }
  creditorName
  amount
  currency
  date
  description
}
    ${BaseEntityLikeFragmentDoc}`;
export const PaymentRemarkFragmentDoc = gql`
    fragment PaymentRemark on PaymentRemark {
  id
  date
  amount
  creditor {
    id
    ...BaseEntityLike
  }
  creditorName
  partiallySettled
  kind
  referenceNumber
  source
}
    ${BaseEntityLikeFragmentDoc}`;
export const CreditReportIncomeFragmentDoc = gql`
    fragment CreditReportIncome on CreditReportIncome {
  incomeYear
  netIncome
  grossIncome
  netWealth
  sumTax
  taxClass
  municipalityCode
}
    `;
export const CreditReportFragmentDoc = gql`
    fragment CreditReport on CreditReport {
  id
  date
  internationalRating
  ratingValue
  creditLimit
  contractLimit
  dbt
  industryDBT
  averageInvoiceValue
  paymentsOnFile
  paymentsStillOwing
  paymentsPaid
  currencyCode
  collateral {
    edges {
      node {
        ...Collateral
      }
    }
  }
  paymentRemarks {
    edges {
      node {
        id
        ...PaymentRemark
      }
    }
  }
  incomes {
    edges {
      node {
        ...CreditReportIncome
      }
    }
  }
}
    ${CollateralFragmentDoc}
${PaymentRemarkFragmentDoc}
${CreditReportIncomeFragmentDoc}`;
export const TableRowValueFragmentDoc = gql`
    fragment TableRowValue on TableRowValueLike {
  link
  tooltip
  copyValue
  iconId
  reviewMeta {
    ...TableRowValueReviewMeta
  }
  ... on TableRowValueString {
    content
  }
  ... on TableRowValueLabeledString {
    label
    content
  }
  ... on TableRowValueRelatedEntity {
    entity {
      id
      ...BaseEntityLike
      ...EntityFlags @include(if: $withFlags)
    }
  }
  ... on TableRowValueOwnership {
    maybeEntity: entity {
      id
      ...BaseEntityLike
      ...EntityFlags @include(if: $withFlags)
      ... on Person {
        pepInfo(team: $team) @include(if: $includePeps) {
          ...PepInfo
        }
      }
    }
    entityName
    ownership: ownershipV2
    uboMeta {
      ...BeneficialOwnerMeta
    }
    shareClasses {
      shareClass
      share
    }
    modifiedAt
    customMeta {
      ...CustomMeta
    }
  }
  ... on TableRowValueRoleWithEntity {
    detailedRoles
    roleMetas {
      ...RoleMeta
    }
    entity {
      ...BaseEntityLike
      ...EntityFlags @include(if: $withFlags)
    }
  }
  ... on TableRowValueRole {
    roleMeta {
      ...RoleMeta
    }
  }
  ... on TableRowValueFlag {
    flag {
      ...AdverseFlag
    }
  }
  ... on TableRowValueRegisterV2 {
    register {
      ...Register
    }
  }
  ... on TableRowValueEvent {
    event {
      ...BaseEvent
    }
    topics {
      ...Topic
    }
  }
  ... on TableRowValueCreditReport {
    creditReport {
      ...CreditReport
    }
  }
  ... on TableRowValueCCJs {
    ccjs {
      ...CountyCourtJudgement
    }
  }
  ... on TableRowValuePepsWithEntity {
    maybeEntity: entity {
      ...BaseEntityLike
      ...EntityFlags @include(if: $withFlags)
    }
    entityName
    pepInfo {
      ...PepInfo
    }
  }
  ... on TableRowValueSanctions {
    sanctions {
      ...SanctionInfo
    }
  }
  ... on TableRowValueSanctionsWithEntity {
    maybeEntity: entity {
      ...BaseEntityLike
      ...EntityFlags @include(if: $withFlags)
    }
    entityName
    sanctions {
      ...SanctionInfo
    }
  }
  ... on TableRowValueRoleForPerson {
    roleMeta {
      ...RoleMeta
    }
    roleEntity {
      ...BaseEntityLike
      ...EntityFlags @include(if: $withFlags)
    }
  }
  ... on TableRowValueRoleWithDetailedEntity {
    roleMeta {
      ...RoleMeta
    }
    roleEntity: entity {
      ...BaseEntityLike
      ...EntityFlags @include(if: $withFlags)
    }
    otherRoles {
      edges {
        node {
          ...BaseEntityLike
          ...EntityFlags @include(if: $withFlags)
        }
        role {
          ...RoleMeta
        }
      }
    }
    otherIndustries
  }
}
    ${TableRowValueReviewMetaFragmentDoc}
${BaseEntityLikeFragmentDoc}
${EntityFlagsFragmentDoc}
${PepInfoFragmentDoc}
${BeneficialOwnerMetaFragmentDoc}
${CustomMetaFragmentDoc}
${RoleMetaFragmentDoc}
${AdverseFlagFragmentDoc}
${RegisterFragmentDoc}
${BaseEventFragmentDoc}
${TopicFragmentDoc}
${CreditReportFragmentDoc}
${CountyCourtJudgementFragmentDoc}
${SanctionInfoFragmentDoc}`;
export const TableRowSectionFragmentDoc = gql`
    fragment TableRowSection on TableRowSection {
  label {
    ...TableLabel
  }
  paginationThreshold
  values {
    ...TableRowValue
  }
}
    ${TableLabelFragmentDoc}
${TableRowValueFragmentDoc}`;
export const TableRowFragmentDoc = gql`
    fragment TableRow on TableRow {
  key
  label {
    ...TableLabel
  }
  kind
  paginationThreshold
  sections {
    ...TableRowSection
  }
}
    ${TableLabelFragmentDoc}
${TableRowSectionFragmentDoc}`;
export const TableFragmentDoc = gql`
    fragment Table on Table {
  title
  description
  rows {
    ...TableRow
  }
  dataSources {
    ...DataSource
  }
  hasCustomData
  lastModifiedAt
}
    ${TableRowFragmentDoc}
${DataSourceFragmentDoc}`;
export const TeamSimpleUserConnectionFragmentDoc = gql`
    fragment TeamSimpleUserConnection on TeamSimpleUserConnectionEdge {
  node {
    id
    ...SimpleUser
  }
  role {
    id
    name
  }
  status
}
    ${SimpleUserFragmentDoc}`;
export const TeamSimpleUsersFragmentDoc = gql`
    fragment TeamSimpleUsers on Team {
  id
  users: simpleUsers(q: "") {
    edges {
      ...TeamSimpleUserConnection
    }
  }
}
    ${TeamSimpleUserConnectionFragmentDoc}`;
export const TermsFragmentDoc = gql`
    fragment Terms on Settings {
  terms {
    generalTerms
    privacyTerms
  }
}
    `;
export const TeamReviewSettingsV2FragmentDoc = gql`
    fragment TeamReviewSettingsV2 on TeamSettings {
  reviewSettingsV2 {
    settings {
      ... on BooleanReviewSetting {
        enabled
        kind
      }
      ... on StringReviewSetting {
        stringRiskValues: riskValues
        enabled
        kind
      }
      ... on RiskReviewSetting {
        reviewRiskValues: riskValues
        enabled
        kind
      }
      ... on FlagReviewSetting {
        flagRiskValues: riskValues
        enabled
        kind
      }
      ... on EntityReviewSetting {
        entityRiskValues: riskValues
        enabled
        kind
      }
      ... on EntityWithCountryReviewSetting {
        entityWithCountryRiskValues: riskValues {
          entity
          country
        }
        enabled
        kind
      }
    }
    customCheckboxes {
      name
      enabled
      description
    }
    enabledPdfSections
  }
}
    `;
export const TeamReviewTriggerSettingsFragmentDoc = gql`
    fragment TeamReviewTriggerSettings on TeamSettings {
  reviewTriggerSettings {
    triggers {
      statements {
        kind
        operator
        value
        statementKindFilter
      }
    }
  }
}
    `;
export const TeamSanctionsSettingsFragmentDoc = gql`
    fragment TeamSanctionsSettings on TeamSettings {
  sanctionSettings {
    similarityScoreThreshold
    filterOnInception
    filterOnNationality
  }
}
    `;
export const TeamSettingsFragmentDoc = gql`
    fragment TeamSettings on Team {
  id
  name
  teamSettings {
    teamEmails {
      email
    }
    onlySendDocumentsToTeamEmails
    ...TeamReviewSettingsV2
    ...TeamReviewTriggerSettings
    ...TeamSanctionsSettings
    flagFilterSettings {
      period
    }
  }
}
    ${TeamReviewSettingsV2FragmentDoc}
${TeamReviewTriggerSettingsFragmentDoc}
${TeamSanctionsSettingsFragmentDoc}`;
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    id
    ...CurrentUser
  }
}
    ${CurrentUserFragmentDoc}`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export function useCurrentUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserSuspenseQueryHookResult = ReturnType<typeof useCurrentUserSuspenseQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>;
export const EntityDocument = gql`
    query Entity($id: ID!) {
  entity(id: $id) {
    id
    ...BaseEntityLike
  }
}
    ${BaseEntityLikeFragmentDoc}`;

/**
 * __useEntityQuery__
 *
 * To run a query within a React component, call `useEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEntityQuery(baseOptions: Apollo.QueryHookOptions<Types.EntityQuery, Types.EntityQueryVariables> & ({ variables: Types.EntityQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EntityQuery, Types.EntityQueryVariables>(EntityDocument, options);
      }
export function useEntityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EntityQuery, Types.EntityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EntityQuery, Types.EntityQueryVariables>(EntityDocument, options);
        }
export function useEntitySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.EntityQuery, Types.EntityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EntityQuery, Types.EntityQueryVariables>(EntityDocument, options);
        }
export type EntityQueryHookResult = ReturnType<typeof useEntityQuery>;
export type EntityLazyQueryHookResult = ReturnType<typeof useEntityLazyQuery>;
export type EntitySuspenseQueryHookResult = ReturnType<typeof useEntitySuspenseQuery>;
export type EntityQueryResult = Apollo.QueryResult<Types.EntityQuery, Types.EntityQueryVariables>;
export const EntityFlagsDocument = gql`
    query EntityFlags($id: ID!) {
  entity(id: $id) {
    id
    name
    ...EntityFlags
  }
}
    ${EntityFlagsFragmentDoc}`;

/**
 * __useEntityFlagsQuery__
 *
 * To run a query within a React component, call `useEntityFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityFlagsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEntityFlagsQuery(baseOptions: Apollo.QueryHookOptions<Types.EntityFlagsQuery, Types.EntityFlagsQueryVariables> & ({ variables: Types.EntityFlagsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EntityFlagsQuery, Types.EntityFlagsQueryVariables>(EntityFlagsDocument, options);
      }
export function useEntityFlagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EntityFlagsQuery, Types.EntityFlagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EntityFlagsQuery, Types.EntityFlagsQueryVariables>(EntityFlagsDocument, options);
        }
export function useEntityFlagsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.EntityFlagsQuery, Types.EntityFlagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EntityFlagsQuery, Types.EntityFlagsQueryVariables>(EntityFlagsDocument, options);
        }
export type EntityFlagsQueryHookResult = ReturnType<typeof useEntityFlagsQuery>;
export type EntityFlagsLazyQueryHookResult = ReturnType<typeof useEntityFlagsLazyQuery>;
export type EntityFlagsSuspenseQueryHookResult = ReturnType<typeof useEntityFlagsSuspenseQuery>;
export type EntityFlagsQueryResult = Apollo.QueryResult<Types.EntityFlagsQuery, Types.EntityFlagsQueryVariables>;
export const UpdateCurrentUserFeaturesDocument = gql`
    mutation UpdateCurrentUserFeatures($user: ID!, $team: ID!, $features: [FeatureInput!]!, $includeFeatureCategories: [FeatureCategoryKind!], $excludeFeatureCategories: [FeatureCategoryKind!], $includeIntegration: Boolean = false) {
  updateUserFeatures: updateUserFeaturesV2(
    user: $user
    team: $team
    features: $features
  ) {
    currentUser {
      id
      features: featuresV2(team: $team) {
        ...Features
      }
    }
  }
}
    ${FeaturesFragmentDoc}`;
export type UpdateCurrentUserFeaturesMutationFn = Apollo.MutationFunction<Types.UpdateCurrentUserFeaturesMutation, Types.UpdateCurrentUserFeaturesMutationVariables>;

/**
 * __useUpdateCurrentUserFeaturesMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserFeaturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserFeaturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserFeaturesMutation, { data, loading, error }] = useUpdateCurrentUserFeaturesMutation({
 *   variables: {
 *      user: // value for 'user'
 *      team: // value for 'team'
 *      features: // value for 'features'
 *      includeFeatureCategories: // value for 'includeFeatureCategories'
 *      excludeFeatureCategories: // value for 'excludeFeatureCategories'
 *      includeIntegration: // value for 'includeIntegration'
 *   },
 * });
 */
export function useUpdateCurrentUserFeaturesMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCurrentUserFeaturesMutation, Types.UpdateCurrentUserFeaturesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCurrentUserFeaturesMutation, Types.UpdateCurrentUserFeaturesMutationVariables>(UpdateCurrentUserFeaturesDocument, options);
      }
export type UpdateCurrentUserFeaturesMutationHookResult = ReturnType<typeof useUpdateCurrentUserFeaturesMutation>;
export type UpdateCurrentUserFeaturesMutationResult = Apollo.MutationResult<Types.UpdateCurrentUserFeaturesMutation>;
export type UpdateCurrentUserFeaturesMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCurrentUserFeaturesMutation, Types.UpdateCurrentUserFeaturesMutationVariables>;
export const UpdateTeamFeaturesDocument = gql`
    mutation UpdateTeamFeatures($team: ID!, $features: [FeatureInput!]!, $includeFeatureCategories: [FeatureCategoryKind!], $excludeFeatureCategories: [FeatureCategoryKind!], $includeIntegration: Boolean = false) {
  updateTeamFeatures: updateTeamFeaturesV2(team: $team, features: $features) {
    team(id: $team) {
      id
      features: featuresV2 {
        ...Features
      }
    }
  }
}
    ${FeaturesFragmentDoc}`;
export type UpdateTeamFeaturesMutationFn = Apollo.MutationFunction<Types.UpdateTeamFeaturesMutation, Types.UpdateTeamFeaturesMutationVariables>;

/**
 * __useUpdateTeamFeaturesMutation__
 *
 * To run a mutation, you first call `useUpdateTeamFeaturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamFeaturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamFeaturesMutation, { data, loading, error }] = useUpdateTeamFeaturesMutation({
 *   variables: {
 *      team: // value for 'team'
 *      features: // value for 'features'
 *      includeFeatureCategories: // value for 'includeFeatureCategories'
 *      excludeFeatureCategories: // value for 'excludeFeatureCategories'
 *      includeIntegration: // value for 'includeIntegration'
 *   },
 * });
 */
export function useUpdateTeamFeaturesMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTeamFeaturesMutation, Types.UpdateTeamFeaturesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateTeamFeaturesMutation, Types.UpdateTeamFeaturesMutationVariables>(UpdateTeamFeaturesDocument, options);
      }
export type UpdateTeamFeaturesMutationHookResult = ReturnType<typeof useUpdateTeamFeaturesMutation>;
export type UpdateTeamFeaturesMutationResult = Apollo.MutationResult<Types.UpdateTeamFeaturesMutation>;
export type UpdateTeamFeaturesMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTeamFeaturesMutation, Types.UpdateTeamFeaturesMutationVariables>;
export const UpdateUserFeaturesDocument = gql`
    mutation UpdateUserFeatures($user: ID!, $team: ID!, $features: [FeatureInput!]!, $includeFeatureCategories: [FeatureCategoryKind!], $excludeFeatureCategories: [FeatureCategoryKind!], $includeIntegration: Boolean = false) {
  updateUserFeatures: updateUserFeaturesV2(
    user: $user
    team: $team
    features: $features
  ) {
    user(id: $user) {
      id
      features: featuresV2(team: $team) {
        ...Features
      }
    }
  }
}
    ${FeaturesFragmentDoc}`;
export type UpdateUserFeaturesMutationFn = Apollo.MutationFunction<Types.UpdateUserFeaturesMutation, Types.UpdateUserFeaturesMutationVariables>;

/**
 * __useUpdateUserFeaturesMutation__
 *
 * To run a mutation, you first call `useUpdateUserFeaturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserFeaturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserFeaturesMutation, { data, loading, error }] = useUpdateUserFeaturesMutation({
 *   variables: {
 *      user: // value for 'user'
 *      team: // value for 'team'
 *      features: // value for 'features'
 *      includeFeatureCategories: // value for 'includeFeatureCategories'
 *      excludeFeatureCategories: // value for 'excludeFeatureCategories'
 *      includeIntegration: // value for 'includeIntegration'
 *   },
 * });
 */
export function useUpdateUserFeaturesMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserFeaturesMutation, Types.UpdateUserFeaturesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserFeaturesMutation, Types.UpdateUserFeaturesMutationVariables>(UpdateUserFeaturesDocument, options);
      }
export type UpdateUserFeaturesMutationHookResult = ReturnType<typeof useUpdateUserFeaturesMutation>;
export type UpdateUserFeaturesMutationResult = Apollo.MutationResult<Types.UpdateUserFeaturesMutation>;
export type UpdateUserFeaturesMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserFeaturesMutation, Types.UpdateUserFeaturesMutationVariables>;
export const CurrentUserFeaturesDocument = gql`
    query CurrentUserFeatures($team: ID!, $includeFeatureCategories: [FeatureCategoryKind!], $excludeFeatureCategories: [FeatureCategoryKind!], $includeIntegration: Boolean = false) {
  currentUser {
    id
    features: featuresV2(team: $team) {
      ...Features
    }
  }
}
    ${FeaturesFragmentDoc}`;

/**
 * __useCurrentUserFeaturesQuery__
 *
 * To run a query within a React component, call `useCurrentUserFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserFeaturesQuery({
 *   variables: {
 *      team: // value for 'team'
 *      includeFeatureCategories: // value for 'includeFeatureCategories'
 *      excludeFeatureCategories: // value for 'excludeFeatureCategories'
 *      includeIntegration: // value for 'includeIntegration'
 *   },
 * });
 */
export function useCurrentUserFeaturesQuery(baseOptions: Apollo.QueryHookOptions<Types.CurrentUserFeaturesQuery, Types.CurrentUserFeaturesQueryVariables> & ({ variables: Types.CurrentUserFeaturesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CurrentUserFeaturesQuery, Types.CurrentUserFeaturesQueryVariables>(CurrentUserFeaturesDocument, options);
      }
export function useCurrentUserFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CurrentUserFeaturesQuery, Types.CurrentUserFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CurrentUserFeaturesQuery, Types.CurrentUserFeaturesQueryVariables>(CurrentUserFeaturesDocument, options);
        }
export function useCurrentUserFeaturesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CurrentUserFeaturesQuery, Types.CurrentUserFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CurrentUserFeaturesQuery, Types.CurrentUserFeaturesQueryVariables>(CurrentUserFeaturesDocument, options);
        }
export type CurrentUserFeaturesQueryHookResult = ReturnType<typeof useCurrentUserFeaturesQuery>;
export type CurrentUserFeaturesLazyQueryHookResult = ReturnType<typeof useCurrentUserFeaturesLazyQuery>;
export type CurrentUserFeaturesSuspenseQueryHookResult = ReturnType<typeof useCurrentUserFeaturesSuspenseQuery>;
export type CurrentUserFeaturesQueryResult = Apollo.QueryResult<Types.CurrentUserFeaturesQuery, Types.CurrentUserFeaturesQueryVariables>;
export const DefaultFeaturesDocument = gql`
    query DefaultFeatures($includeFeatureCategories: [FeatureCategoryKind!], $excludeFeatureCategories: [FeatureCategoryKind!], $includeIntegration: Boolean = false) {
  defaultFeatures(
    includeFeatureCategories: $includeFeatureCategories
    excludeFeatureCategories: $excludeFeatureCategories
  ) {
    ...Feature
  }
}
    ${FeatureFragmentDoc}`;

/**
 * __useDefaultFeaturesQuery__
 *
 * To run a query within a React component, call `useDefaultFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultFeaturesQuery({
 *   variables: {
 *      includeFeatureCategories: // value for 'includeFeatureCategories'
 *      excludeFeatureCategories: // value for 'excludeFeatureCategories'
 *      includeIntegration: // value for 'includeIntegration'
 *   },
 * });
 */
export function useDefaultFeaturesQuery(baseOptions?: Apollo.QueryHookOptions<Types.DefaultFeaturesQuery, Types.DefaultFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DefaultFeaturesQuery, Types.DefaultFeaturesQueryVariables>(DefaultFeaturesDocument, options);
      }
export function useDefaultFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DefaultFeaturesQuery, Types.DefaultFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DefaultFeaturesQuery, Types.DefaultFeaturesQueryVariables>(DefaultFeaturesDocument, options);
        }
export function useDefaultFeaturesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.DefaultFeaturesQuery, Types.DefaultFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.DefaultFeaturesQuery, Types.DefaultFeaturesQueryVariables>(DefaultFeaturesDocument, options);
        }
export type DefaultFeaturesQueryHookResult = ReturnType<typeof useDefaultFeaturesQuery>;
export type DefaultFeaturesLazyQueryHookResult = ReturnType<typeof useDefaultFeaturesLazyQuery>;
export type DefaultFeaturesSuspenseQueryHookResult = ReturnType<typeof useDefaultFeaturesSuspenseQuery>;
export type DefaultFeaturesQueryResult = Apollo.QueryResult<Types.DefaultFeaturesQuery, Types.DefaultFeaturesQueryVariables>;
export const TeamFeaturesDocument = gql`
    query TeamFeatures($team: ID!, $includeFeatureCategories: [FeatureCategoryKind!], $excludeFeatureCategories: [FeatureCategoryKind!], $includeIntegration: Boolean = false) {
  team(id: $team) {
    id
    features: featuresV2 {
      ...Features
    }
  }
}
    ${FeaturesFragmentDoc}`;

/**
 * __useTeamFeaturesQuery__
 *
 * To run a query within a React component, call `useTeamFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamFeaturesQuery({
 *   variables: {
 *      team: // value for 'team'
 *      includeFeatureCategories: // value for 'includeFeatureCategories'
 *      excludeFeatureCategories: // value for 'excludeFeatureCategories'
 *      includeIntegration: // value for 'includeIntegration'
 *   },
 * });
 */
export function useTeamFeaturesQuery(baseOptions: Apollo.QueryHookOptions<Types.TeamFeaturesQuery, Types.TeamFeaturesQueryVariables> & ({ variables: Types.TeamFeaturesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TeamFeaturesQuery, Types.TeamFeaturesQueryVariables>(TeamFeaturesDocument, options);
      }
export function useTeamFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TeamFeaturesQuery, Types.TeamFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TeamFeaturesQuery, Types.TeamFeaturesQueryVariables>(TeamFeaturesDocument, options);
        }
export function useTeamFeaturesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TeamFeaturesQuery, Types.TeamFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TeamFeaturesQuery, Types.TeamFeaturesQueryVariables>(TeamFeaturesDocument, options);
        }
export type TeamFeaturesQueryHookResult = ReturnType<typeof useTeamFeaturesQuery>;
export type TeamFeaturesLazyQueryHookResult = ReturnType<typeof useTeamFeaturesLazyQuery>;
export type TeamFeaturesSuspenseQueryHookResult = ReturnType<typeof useTeamFeaturesSuspenseQuery>;
export type TeamFeaturesQueryResult = Apollo.QueryResult<Types.TeamFeaturesQuery, Types.TeamFeaturesQueryVariables>;
export const UserFeaturesDocument = gql`
    query UserFeatures($team: ID!, $user: ID!, $includeFeatureCategories: [FeatureCategoryKind!], $excludeFeatureCategories: [FeatureCategoryKind!], $includeIntegration: Boolean = false) {
  user(id: $user) {
    id
    features: featuresV2(team: $team) {
      ...Features
    }
  }
}
    ${FeaturesFragmentDoc}`;

/**
 * __useUserFeaturesQuery__
 *
 * To run a query within a React component, call `useUserFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFeaturesQuery({
 *   variables: {
 *      team: // value for 'team'
 *      user: // value for 'user'
 *      includeFeatureCategories: // value for 'includeFeatureCategories'
 *      excludeFeatureCategories: // value for 'excludeFeatureCategories'
 *      includeIntegration: // value for 'includeIntegration'
 *   },
 * });
 */
export function useUserFeaturesQuery(baseOptions: Apollo.QueryHookOptions<Types.UserFeaturesQuery, Types.UserFeaturesQueryVariables> & ({ variables: Types.UserFeaturesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserFeaturesQuery, Types.UserFeaturesQueryVariables>(UserFeaturesDocument, options);
      }
export function useUserFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserFeaturesQuery, Types.UserFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserFeaturesQuery, Types.UserFeaturesQueryVariables>(UserFeaturesDocument, options);
        }
export function useUserFeaturesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.UserFeaturesQuery, Types.UserFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.UserFeaturesQuery, Types.UserFeaturesQueryVariables>(UserFeaturesDocument, options);
        }
export type UserFeaturesQueryHookResult = ReturnType<typeof useUserFeaturesQuery>;
export type UserFeaturesLazyQueryHookResult = ReturnType<typeof useUserFeaturesLazyQuery>;
export type UserFeaturesSuspenseQueryHookResult = ReturnType<typeof useUserFeaturesSuspenseQuery>;
export type UserFeaturesQueryResult = Apollo.QueryResult<Types.UserFeaturesQuery, Types.UserFeaturesQueryVariables>;
export const FinancialFieldsDocument = gql`
    query FinancialFields($countries: [ContentLanguage!]) {
  financialFields(countries: $countries) {
    id
    ...FinancialFieldInfo
    matchingInternationalFields {
      id
      ...FinancialFieldInfo
    }
  }
}
    ${FinancialFieldInfoFragmentDoc}`;

/**
 * __useFinancialFieldsQuery__
 *
 * To run a query within a React component, call `useFinancialFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinancialFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinancialFieldsQuery({
 *   variables: {
 *      countries: // value for 'countries'
 *   },
 * });
 */
export function useFinancialFieldsQuery(baseOptions?: Apollo.QueryHookOptions<Types.FinancialFieldsQuery, Types.FinancialFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FinancialFieldsQuery, Types.FinancialFieldsQueryVariables>(FinancialFieldsDocument, options);
      }
export function useFinancialFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FinancialFieldsQuery, Types.FinancialFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FinancialFieldsQuery, Types.FinancialFieldsQueryVariables>(FinancialFieldsDocument, options);
        }
export function useFinancialFieldsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.FinancialFieldsQuery, Types.FinancialFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.FinancialFieldsQuery, Types.FinancialFieldsQueryVariables>(FinancialFieldsDocument, options);
        }
export type FinancialFieldsQueryHookResult = ReturnType<typeof useFinancialFieldsQuery>;
export type FinancialFieldsLazyQueryHookResult = ReturnType<typeof useFinancialFieldsLazyQuery>;
export type FinancialFieldsSuspenseQueryHookResult = ReturnType<typeof useFinancialFieldsSuspenseQuery>;
export type FinancialFieldsQueryResult = Apollo.QueryResult<Types.FinancialFieldsQuery, Types.FinancialFieldsQueryVariables>;
export const DefaultFinancialFieldsDocument = gql`
    query DefaultFinancialFields($contentLanguage: ContentLanguage!) {
  defaultFinancialFieldsByContentLanguage(contentLanguage: $contentLanguage) {
    id
    ...FinancialFieldInfo
  }
}
    ${FinancialFieldInfoFragmentDoc}`;

/**
 * __useDefaultFinancialFieldsQuery__
 *
 * To run a query within a React component, call `useDefaultFinancialFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultFinancialFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultFinancialFieldsQuery({
 *   variables: {
 *      contentLanguage: // value for 'contentLanguage'
 *   },
 * });
 */
export function useDefaultFinancialFieldsQuery(baseOptions: Apollo.QueryHookOptions<Types.DefaultFinancialFieldsQuery, Types.DefaultFinancialFieldsQueryVariables> & ({ variables: Types.DefaultFinancialFieldsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DefaultFinancialFieldsQuery, Types.DefaultFinancialFieldsQueryVariables>(DefaultFinancialFieldsDocument, options);
      }
export function useDefaultFinancialFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DefaultFinancialFieldsQuery, Types.DefaultFinancialFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DefaultFinancialFieldsQuery, Types.DefaultFinancialFieldsQueryVariables>(DefaultFinancialFieldsDocument, options);
        }
export function useDefaultFinancialFieldsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.DefaultFinancialFieldsQuery, Types.DefaultFinancialFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.DefaultFinancialFieldsQuery, Types.DefaultFinancialFieldsQueryVariables>(DefaultFinancialFieldsDocument, options);
        }
export type DefaultFinancialFieldsQueryHookResult = ReturnType<typeof useDefaultFinancialFieldsQuery>;
export type DefaultFinancialFieldsLazyQueryHookResult = ReturnType<typeof useDefaultFinancialFieldsLazyQuery>;
export type DefaultFinancialFieldsSuspenseQueryHookResult = ReturnType<typeof useDefaultFinancialFieldsSuspenseQuery>;
export type DefaultFinancialFieldsQueryResult = Apollo.QueryResult<Types.DefaultFinancialFieldsQuery, Types.DefaultFinancialFieldsQueryVariables>;
export const LatestRateLimitDocument = gql`
    query LatestRateLimit($customer: ID!, $kind: RateLimitKind!) {
  latestRateLimit(customer: $customer, kind: $kind) {
    id
    ...BaseRateLimit
  }
}
    ${BaseRateLimitFragmentDoc}`;

/**
 * __useLatestRateLimitQuery__
 *
 * To run a query within a React component, call `useLatestRateLimitQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestRateLimitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestRateLimitQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useLatestRateLimitQuery(baseOptions: Apollo.QueryHookOptions<Types.LatestRateLimitQuery, Types.LatestRateLimitQueryVariables> & ({ variables: Types.LatestRateLimitQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LatestRateLimitQuery, Types.LatestRateLimitQueryVariables>(LatestRateLimitDocument, options);
      }
export function useLatestRateLimitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LatestRateLimitQuery, Types.LatestRateLimitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LatestRateLimitQuery, Types.LatestRateLimitQueryVariables>(LatestRateLimitDocument, options);
        }
export function useLatestRateLimitSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.LatestRateLimitQuery, Types.LatestRateLimitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.LatestRateLimitQuery, Types.LatestRateLimitQueryVariables>(LatestRateLimitDocument, options);
        }
export type LatestRateLimitQueryHookResult = ReturnType<typeof useLatestRateLimitQuery>;
export type LatestRateLimitLazyQueryHookResult = ReturnType<typeof useLatestRateLimitLazyQuery>;
export type LatestRateLimitSuspenseQueryHookResult = ReturnType<typeof useLatestRateLimitSuspenseQuery>;
export type LatestRateLimitQueryResult = Apollo.QueryResult<Types.LatestRateLimitQuery, Types.LatestRateLimitQueryVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($user: ID!) {
  resetPassword(user: $user) {
    id
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<Types.ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>;
export const EntitySanctionsDocument = gql`
    query EntitySanctions($id: ID!, $teamId: ID!) {
  entity(id: $id) {
    id
    name
    ...EntitySanctionInfo
  }
}
    ${EntitySanctionInfoFragmentDoc}`;

/**
 * __useEntitySanctionsQuery__
 *
 * To run a query within a React component, call `useEntitySanctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntitySanctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntitySanctionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useEntitySanctionsQuery(baseOptions: Apollo.QueryHookOptions<Types.EntitySanctionsQuery, Types.EntitySanctionsQueryVariables> & ({ variables: Types.EntitySanctionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EntitySanctionsQuery, Types.EntitySanctionsQueryVariables>(EntitySanctionsDocument, options);
      }
export function useEntitySanctionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EntitySanctionsQuery, Types.EntitySanctionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EntitySanctionsQuery, Types.EntitySanctionsQueryVariables>(EntitySanctionsDocument, options);
        }
export function useEntitySanctionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.EntitySanctionsQuery, Types.EntitySanctionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EntitySanctionsQuery, Types.EntitySanctionsQueryVariables>(EntitySanctionsDocument, options);
        }
export type EntitySanctionsQueryHookResult = ReturnType<typeof useEntitySanctionsQuery>;
export type EntitySanctionsLazyQueryHookResult = ReturnType<typeof useEntitySanctionsLazyQuery>;
export type EntitySanctionsSuspenseQueryHookResult = ReturnType<typeof useEntitySanctionsSuspenseQuery>;
export type EntitySanctionsQueryResult = Apollo.QueryResult<Types.EntitySanctionsQuery, Types.EntitySanctionsQueryVariables>;
export const TagDocument = gql`
    query Tag($id: ID!) {
  tag: sticker(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useTagQuery__
 *
 * To run a query within a React component, call `useTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagQuery(baseOptions: Apollo.QueryHookOptions<Types.TagQuery, Types.TagQueryVariables> & ({ variables: Types.TagQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TagQuery, Types.TagQueryVariables>(TagDocument, options);
      }
export function useTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TagQuery, Types.TagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TagQuery, Types.TagQueryVariables>(TagDocument, options);
        }
export function useTagSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TagQuery, Types.TagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TagQuery, Types.TagQueryVariables>(TagDocument, options);
        }
export type TagQueryHookResult = ReturnType<typeof useTagQuery>;
export type TagLazyQueryHookResult = ReturnType<typeof useTagLazyQuery>;
export type TagSuspenseQueryHookResult = ReturnType<typeof useTagSuspenseQuery>;
export type TagQueryResult = Apollo.QueryResult<Types.TagQuery, Types.TagQueryVariables>;
export const UpdateTeamSettingsDocument = gql`
    mutation UpdateTeamSettings($team: ID!, $input: TeamSettingsInput!) {
  updateTeamSettingsV2(team: $team, input: $input) {
    team(id: $team) {
      id
      ...TeamSettings
    }
  }
}
    ${TeamSettingsFragmentDoc}`;
export type UpdateTeamSettingsMutationFn = Apollo.MutationFunction<Types.UpdateTeamSettingsMutation, Types.UpdateTeamSettingsMutationVariables>;

/**
 * __useUpdateTeamSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateTeamSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamSettingsMutation, { data, loading, error }] = useUpdateTeamSettingsMutation({
 *   variables: {
 *      team: // value for 'team'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamSettingsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTeamSettingsMutation, Types.UpdateTeamSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateTeamSettingsMutation, Types.UpdateTeamSettingsMutationVariables>(UpdateTeamSettingsDocument, options);
      }
export type UpdateTeamSettingsMutationHookResult = ReturnType<typeof useUpdateTeamSettingsMutation>;
export type UpdateTeamSettingsMutationResult = Apollo.MutationResult<Types.UpdateTeamSettingsMutation>;
export type UpdateTeamSettingsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTeamSettingsMutation, Types.UpdateTeamSettingsMutationVariables>;
export const UpdateCompanyStatusesDocument = gql`
    mutation UpdateCompanyStatuses($portfolio: ID!, $team: ID!, $companies: [ID!]!, $status: CompanyStatus) {
  updateCompanyStatuses(
    portfolio: $portfolio
    team: $team
    companies: $companies
    status: $status
  ) {
    team(id: $team) {
      id
      portfolio(id: $portfolio) {
        id
        companies(filter: {companies: $companies}) {
          edges {
            node {
              id
              statusV2(team: $team, portfolio: $portfolio) {
                ...TeamCompanyStatus
              }
            }
          }
        }
      }
    }
  }
}
    ${TeamCompanyStatusFragmentDoc}`;
export type UpdateCompanyStatusesMutationFn = Apollo.MutationFunction<Types.UpdateCompanyStatusesMutation, Types.UpdateCompanyStatusesMutationVariables>;

/**
 * __useUpdateCompanyStatusesMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyStatusesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyStatusesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyStatusesMutation, { data, loading, error }] = useUpdateCompanyStatusesMutation({
 *   variables: {
 *      portfolio: // value for 'portfolio'
 *      team: // value for 'team'
 *      companies: // value for 'companies'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateCompanyStatusesMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyStatusesMutation, Types.UpdateCompanyStatusesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyStatusesMutation, Types.UpdateCompanyStatusesMutationVariables>(UpdateCompanyStatusesDocument, options);
      }
export type UpdateCompanyStatusesMutationHookResult = ReturnType<typeof useUpdateCompanyStatusesMutation>;
export type UpdateCompanyStatusesMutationResult = Apollo.MutationResult<Types.UpdateCompanyStatusesMutation>;
export type UpdateCompanyStatusesMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyStatusesMutation, Types.UpdateCompanyStatusesMutationVariables>;
export const AddRowsToPortfolioDocument = gql`
    mutation AddRowsToPortfolio($rows: [PortfolioCompanyRowInput!]!, $team: ID!, $portfolio: ID!, $mergeStrategyKind: PortfolioMergeStrategyKind!) {
  addRowsToPortfolio(
    rows: $rows
    team: $team
    portfolio: $portfolio
    mergeStrategyKind: $mergeStrategyKind
  ) {
    strise
  }
}
    `;
export type AddRowsToPortfolioMutationFn = Apollo.MutationFunction<Types.AddRowsToPortfolioMutation, Types.AddRowsToPortfolioMutationVariables>;

/**
 * __useAddRowsToPortfolioMutation__
 *
 * To run a mutation, you first call `useAddRowsToPortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRowsToPortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRowsToPortfolioMutation, { data, loading, error }] = useAddRowsToPortfolioMutation({
 *   variables: {
 *      rows: // value for 'rows'
 *      team: // value for 'team'
 *      portfolio: // value for 'portfolio'
 *      mergeStrategyKind: // value for 'mergeStrategyKind'
 *   },
 * });
 */
export function useAddRowsToPortfolioMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddRowsToPortfolioMutation, Types.AddRowsToPortfolioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddRowsToPortfolioMutation, Types.AddRowsToPortfolioMutationVariables>(AddRowsToPortfolioDocument, options);
      }
export type AddRowsToPortfolioMutationHookResult = ReturnType<typeof useAddRowsToPortfolioMutation>;
export type AddRowsToPortfolioMutationResult = Apollo.MutationResult<Types.AddRowsToPortfolioMutation>;
export type AddRowsToPortfolioMutationOptions = Apollo.BaseMutationOptions<Types.AddRowsToPortfolioMutation, Types.AddRowsToPortfolioMutationVariables>;
export const ValidatePortfolioCsvDocument = gql`
    query ValidatePortfolioCsv($csv: String!, $team: ID!, $portfolio: ID!) {
  validatePortfolioCsv(csv: $csv, team: $team) {
    rows {
      company {
        id
        ...CompanyMeta
      }
      assignees {
        id
        ...SimpleUser
      }
      tags {
        name
        existing {
          id
        }
      }
      status
      errors {
        message
        line
      }
    }
    errors {
      message
      line
    }
    lines
    duplicates
  }
}
    ${CompanyMetaFragmentDoc}
${SimpleUserFragmentDoc}`;

/**
 * __useValidatePortfolioCsvQuery__
 *
 * To run a query within a React component, call `useValidatePortfolioCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidatePortfolioCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidatePortfolioCsvQuery({
 *   variables: {
 *      csv: // value for 'csv'
 *      team: // value for 'team'
 *      portfolio: // value for 'portfolio'
 *   },
 * });
 */
export function useValidatePortfolioCsvQuery(baseOptions: Apollo.QueryHookOptions<Types.ValidatePortfolioCsvQuery, Types.ValidatePortfolioCsvQueryVariables> & ({ variables: Types.ValidatePortfolioCsvQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ValidatePortfolioCsvQuery, Types.ValidatePortfolioCsvQueryVariables>(ValidatePortfolioCsvDocument, options);
      }
export function useValidatePortfolioCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ValidatePortfolioCsvQuery, Types.ValidatePortfolioCsvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ValidatePortfolioCsvQuery, Types.ValidatePortfolioCsvQueryVariables>(ValidatePortfolioCsvDocument, options);
        }
export function useValidatePortfolioCsvSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ValidatePortfolioCsvQuery, Types.ValidatePortfolioCsvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ValidatePortfolioCsvQuery, Types.ValidatePortfolioCsvQueryVariables>(ValidatePortfolioCsvDocument, options);
        }
export type ValidatePortfolioCsvQueryHookResult = ReturnType<typeof useValidatePortfolioCsvQuery>;
export type ValidatePortfolioCsvLazyQueryHookResult = ReturnType<typeof useValidatePortfolioCsvLazyQuery>;
export type ValidatePortfolioCsvSuspenseQueryHookResult = ReturnType<typeof useValidatePortfolioCsvSuspenseQuery>;
export type ValidatePortfolioCsvQueryResult = Apollo.QueryResult<Types.ValidatePortfolioCsvQuery, Types.ValidatePortfolioCsvQueryVariables>;