import { type UnhandledRequestStrategy } from 'msw/lib/core/utils/request/onUnhandledRequest'

export const onUnhandledRequest: UnhandledRequestStrategy = (request, print) => {
  // Bypass lazy loaded modules, e.g. `import('./module')`.
  if (/\.(js|ts|tsx|png|jpg|woff|svg|css|graphql|html)/.test(request.url)) {
    return
  }

  if (request.url.includes('__webpack_hmr')) {
    return
  }

  // Bypass GitHub avatars, faker generates these urls when using faker.avatar
  if (request.url.includes('avatars.githubusercontent.com')) {
    return
  }

  // Otherwise, print an unhandled request warning.
  print.warning()
}
