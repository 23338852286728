import { Typography, Combobox, type ComboboxProps, cn } from '@strise/midgard'
import { useReactiveVar } from '@apollo/client'
import { type FinancialFieldInfoFragment } from '@graphqlTypes'
import { financialFieldsMap } from '@state'
import { LanguageNationFlags } from '@strise/europa'
import { type ContentLanguage, type FinancialFieldInfo } from '@strise/types'
import { type FinancialId } from '@utils/financialTypes'
import { useFinancialFieldSelectOptions } from '@utils/financials'
import * as React from 'react'
import { FinancialFieldSelectRenderText } from './FinancialFieldSelectRenderText'

export const FinancialSelect: React.FC<
  {
    countries: ContentLanguage[]
    fieldInfo: FinancialFieldInfoFragment
  } & Omit<ComboboxProps<FinancialId>, 'items' | 'value'>
> = ({ className, countries, fieldInfo, ...props }) => {
  const financialsFieldMapState = useReactiveVar(financialFieldsMap)

  const financialFieldMapValues = Object.values(financialsFieldMapState) as FinancialFieldInfo[]

  const options = useFinancialFieldSelectOptions(financialFieldMapValues, FinancialFieldSelectRenderText)

  return (
    <Combobox
      className={cn('h-14 w-full pr-4', className)}
      variant='contained'
      palette='tertiary'
      inlineSearch
      disableSelectedOnTop
      singleSelect
      items={options}
      value={[{ id: fieldInfo.id, label: fieldInfo.name, value: fieldInfo.id }]}
      customSelectedItemsRenderer={
        <div className='flex gap-2'>
          <LanguageNationFlags languages={countries} />
          <Typography>{fieldInfo.name}</Typography>
        </div>
      }
      data-track='false'
      {...props}
    />
  )
}
