import * as React from 'react'
import { type DivProps, htmlToMarkdown } from '@strise/react-utils'
import { cn, IconButton, IconSpark, TextArea, Tooltip, Typography } from '@strise/midgard'
import { useReviewAiSummaryLazyQuery } from '@graphqlOperations'
import { t, Trans } from '@lingui/macro'
import { useCurrentUserFeatures } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { SummarySelection } from '@strise/types'
import { apiEnvState } from '@state'

interface ReviewSummaryProps extends DivProps {
  containerRef: React.RefObject<HTMLDivElement>
  loading?: boolean
  onChange?: (value: string) => void
  value?: string
}

export const ReviewSummarySection = ({
  className,
  containerRef,
  loading: loadingReviewData,
  onChange,
  value
}: ReviewSummaryProps) => {
  const features = useCurrentUserFeatures()
  const [prompt, setPrompt] = React.useState<string>()

  React.useEffect(() => {
    if (containerRef.current) {
      const markdown = htmlToMarkdown(containerRef.current.innerHTML)
      setPrompt(markdown)
    }
  }, [loadingReviewData])

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.(event.target.value)
  }
  return (
    <div className={cn('mt-8', className)}>
      <div className='mb-3 flex items-center justify-between'>
        {/* Need double `mb-3` to get same alignment whether the ReviewAiSummaryButton is rendered or not */}
        <Typography className='mb-3' variant='subtitle1'>
          <Trans>Self-assessment</Trans>
        </Typography>
        {features.REVIEW_AI_SUMMARY && (
          <ReviewAiSummaryButton loadingReviewData={loadingReviewData} prompt={prompt} onCompleted={onChange} />
        )}
      </div>
      <TextArea placeholder={t`Write a comment about this review`} value={value} autoResize onChange={handleChange} />
    </div>
  )
}

interface ReviewAiSummaryButtonProps {
  loadingReviewData?: boolean
  onCompleted?: (summary: string) => void
  prompt?: string
}

const ReviewAiSummaryButton = ({ loadingReviewData, onCompleted, prompt }: ReviewAiSummaryButtonProps) => {
  const [generateSummary, { data, loading: loadingSummary, refetch }] = useReviewAiSummaryLazyQuery({
    fetchPolicy: 'network-only',
    refetchWritePolicy: 'overwrite',
    notifyOnNetworkStatusChange: true,
    onCompleted: (res) => {
      onCompleted?.(res.reviewAiSummary.summary)
    }
  })

  const apiEnv = apiEnvState().get()

  const handleGenerateSummary = () => {
    if (!prompt) return

    // TODO: This is a temporary env check to use nexus-ai in STAGE and LOCAL env only
    const summaryKind = apiEnv === 'STAGE' || apiEnv === 'LOCAL' ? SummarySelection.GenAi : undefined

    if (data) {
      refetch({ text: prompt, summaryKind })
    } else {
      generateSummary({ variables: { text: prompt, summaryKind } })
    }
  }

  const tooltipContent = getTooltipText(loadingReviewData, loadingSummary)

  return (
    <Tooltip content={tooltipContent}>
      {/* Need to wrap the button in a span to be able to render the tooltip when the button is disabled */}
      <span className='inline-block'>
        <IconButton
          disabled={loadingSummary || loadingReviewData || !prompt}
          // Testing out a new pulsating icon loading animation instead of the old spinner
          className={loadingSummary ? 'animate-pulse duration-1000' : ''}
          data-track='Generate summary'
          onClick={handleGenerateSummary}
          aria-label={tooltipContent}
        >
          <IconSpark />
        </IconButton>
      </span>
    </Tooltip>
  )
}

const getTooltipText = (loadingReviewData?: boolean, loadingSummary?: boolean) => {
  if (loadingReviewData) {
    return t`Loading all Review data...`
  }
  if (loadingSummary) {
    return t`Generating AI summary...`
  }
  return t`Generate AI summary`
}
