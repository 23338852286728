import { useReactiveVar } from '@apollo/client'
import { useCompanyRemindersQuery, useCompanyTeamActivitiesQuery } from '@graphqlOperations'
import { i18n, type MessageDescriptor } from '@lingui/core'
import { t, defineMessage, Trans } from '@lingui/macro'
import { refreshCompanyRemindersState } from '@state'
import { useCurrentSimpleUser } from '@strise/europa'
import {
  Button,
  cn,
  IconButton,
  type IconComponent,
  IconPlus,
  IconReminderOverdue,
  IconReminderToday,
  LoaderRound,
  Select,
  Typography
} from '@strise/midgard'
import { ReminderStatus, TeamActivityKind } from '@strise/types'
import { type AssigneeEdge } from '@utils/assigneeUtils'
import { isToday, parseISO } from 'date-fns'
import { sumBy } from 'lodash-es'
import * as React from 'react'
import { DeleteTeamActivity } from './DeleteTeamActivity'
import { TeamActivity } from './TeamActivity'
import { TeamActivityForm } from './TeamActivityForm'
import { TeamActivityFormReminderBackground } from './TeamActivityFormReminderBackground'
import { ActivityFilter, getReminderStatusFilter, type TeamActivityFormData } from './teamActivityUtils'
import { activityFilterOptions } from '@views/Activity/utils/activityUtils'
import { type CompanyMetaFragment } from '@graphqlTypes'

const defaultPageSize = 1
const pageSizeIncrement = 5

const TeamActivitiesReminderLabel: React.FC<{
  ReminderIcon: IconComponent
  className?: string
  label: MessageDescriptor
  totalReminders: number
}> = ({ ReminderIcon, className, label, totalReminders }) => {
  return (
    <Typography className={cn('mx-2 flex h-10 items-center rounded px-4', className)} component='div'>
      <ReminderIcon className='mr-2' />
      {totalReminders} {i18n._(label)}
    </Typography>
  )
}

export const TeamActivities: React.FC<{
  assignees: AssigneeEdge[]
  company: CompanyMetaFragment
}> = ({ assignees, company }) => {
  const formRef = React.useRef(null)
  const refreshCompanyReminders = useReactiveVar(refreshCompanyRemindersState)

  const user = useCurrentSimpleUser()
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<false | string>(false)
  const [pageSize, setPageSize] = React.useState(defaultPageSize)
  const [activityFilter, setActivityFilter] = React.useState<ActivityFilter>(ActivityFilter.ALL_ACTIVITIES)

  const reminderStatus = getReminderStatusFilter(activityFilter)

  const defaultPageInfo = { limit: pageSize, offset: 0 }

  const {
    data,
    fetchMore,
    loading,
    refetch: refetchActivites
  } = useCompanyTeamActivitiesQuery({
    variables: { id: company.id, page: defaultPageInfo, reminderStatus }
  })
  const { data: remindersData, refetch: refetchReminders } = useCompanyRemindersQuery({
    variables: { id: company.id, statusFilter: [ReminderStatus.Active] }
  })

  const defaultFormData: TeamActivityFormData = {
    kind: TeamActivityKind.Note,
    timestamp: null,
    user,
    assignee: user,
    note: '',
    reminderStatus: ReminderStatus.Active
  }

  const [formData, setFormData] = React.useState<TeamActivityFormData>(defaultFormData)

  React.useEffect(() => {
    if (!refreshCompanyReminders) return
    refetchReminders().then((r) => r)
    refetchActivites().then((r) => r)
  }, [refreshCompanyReminders])

  const teamActivities = data?.company.teamActivities.edges ?? []
  const totalCount = data?.company.teamActivities.totalCount ?? 0

  const teamActivitiesLeft = totalCount - teamActivities.length

  const filteredActivities = React.useMemo(() => {
    if (activityFilter === ActivityFilter.COMPLETED_ACTIVITIES) {
      return teamActivities.filter(
        ({ node: teamActivity }) => teamActivity.reminder?.status === ReminderStatus.Inactive
      )
    }

    if (activityFilter === ActivityFilter.PLANNED_ACTIVITIES) {
      return teamActivities.filter(({ node: teamActivity }) => teamActivity.reminder?.status === ReminderStatus.Active)
    }

    return teamActivities
  }, [teamActivities, activityFilter])

  const filterOptions = activityFilterOptions('team-activities')

  const companyReminders = remindersData?.company.reminders.edges ?? []
  const reminders = React.useMemo(() => {
    const hiddenReminders = companyReminders.filter(
      ({ node: reminder }) =>
        !filteredActivities.some(({ node: teamActivity }) => teamActivity.reminder?.id === reminder.id)
    )
    return {
      overdue: sumBy(hiddenReminders, ({ node: reminder }) => +reminder.overdue),
      today: sumBy(hiddenReminders, ({ node: reminder }) => {
        const reminderTime = parseISO(reminder.time)
        return +(!reminder.overdue && isToday(reminderTime))
      })
    }
  }, [filteredActivities, companyReminders])

  const showCreateForm = formData.id === null

  const resetForm = (): void => setFormData(defaultFormData)

  const incrementPageSize = (): void => setPageSize((prevPageSize) => prevPageSize + pageSizeIncrement)
  const reducePageSize = (): void => setPageSize(defaultPageSize)

  const fetchMoreActivities = async (): Promise<void> => {
    incrementPageSize()
    await fetchMore({
      variables: {
        page: { limit: pageSizeIncrement, offset: teamActivities.length }
      }
    })
  }

  const reduceActivities = async (): Promise<void> => {
    reducePageSize()
    await fetchMore({ variables: { page: defaultPageInfo } })
  }

  return (
    <div className='relative'>
      {showCreateForm ? (
        <TeamActivityForm
          ref={formRef}
          resetForm={resetForm}
          company={company}
          formData={formData}
          setFormData={setFormData}
          pageInfo={{ limit: pageSize, offset: 0 }}
          assignees={assignees}
          autoFocus
        />
      ) : (
        <div className='mb-4 flex w-full items-center justify-between'>
          <Button
            data-track='Team activities / Show form'
            startIcon={<IconPlus size='md' className='mr-2' />}
            onClick={() => setFormData({ ...defaultFormData, id: null })}
            className='mr-2 h-12 w-full justify-start px-2'
          >
            <Trans>Create new activity</Trans>
          </Button>
          <Select
            variant='ghost'
            palette='tertiary'
            className='h-12 w-fit whitespace-nowrap'
            options={filterOptions}
            onValueChange={(value) => setActivityFilter(value)}
            value={activityFilter}
            ariaLabel={t`Filter activities`}
          />
        </div>
      )}

      <div>
        {filteredActivities.map(({ node: teamActivity }, index) => {
          const isLast = index === teamActivities.length - 1
          const isExpanded =
            index < pageSizeIncrement - 1 && (index === 0 || index % pageSizeIncrement === 0) && !isLast
          const showUpdateForm = teamActivity.id === formData.id

          return (
            <React.Fragment key={teamActivity.id}>
              {showUpdateForm ? (
                <div
                  className={cn('relative rounded-md bg-secondary-shade-5 py-4 pl-16 pr-2', isLast ? 'mb-3' : 'mb-9')}
                  ref={formRef}
                >
                  <TeamActivityFormReminderBackground teamActivity={teamActivity} />
                  <TeamActivityForm
                    resetForm={resetForm}
                    company={company}
                    formData={formData}
                    setFormData={setFormData}
                    pageInfo={{ limit: pageSize, offset: 0 }}
                    assignees={assignees}
                  />
                </div>
              ) : (
                <TeamActivity
                  setFormData={setFormData}
                  teamActivity={teamActivity}
                  teamActivitiesLeft={teamActivitiesLeft}
                  isLast={isLast}
                  setDeleteDialogOpen={setDeleteDialogOpen}
                />
              )}

              {isExpanded ? (
                <IconButton
                  className='mx-2 mb-5 size-8 rounded-full p-0 text-sm'
                  variant='contained'
                  palette='tertiary'
                  data-track='Team activities / Minimize'
                  onClick={reduceActivities}
                  aria-label={t`Minimize Team activities`}
                >
                  -
                </IconButton>
              ) : (
                isLast && (
                  <div className='mb-1 flex items-center'>
                    {reminders.overdue > 0 && (
                      <TeamActivitiesReminderLabel
                        label={defineMessage({ message: 'overdue' })}
                        className='bg-semantic-danger-shade-5 text-semantic-danger-main'
                        ReminderIcon={IconReminderOverdue}
                        totalReminders={reminders.overdue}
                      />
                    )}

                    {reminders.today > 0 && (
                      <TeamActivitiesReminderLabel
                        label={defineMessage({ message: 'today' })}
                        className='bg-semantic-info-shade-5 text-semantic-info-main'
                        ReminderIcon={IconReminderToday}
                        totalReminders={reminders.today}
                      />
                    )}
                  </div>
                )
              )}

              {deleteDialogOpen === teamActivity.id && (
                <DeleteTeamActivity
                  companyId={company.id}
                  teamActivity={teamActivity}
                  setOpen={setDeleteDialogOpen}
                  pageInfo={{ limit: pageSize, offset: 0 }}
                />
              )}
            </React.Fragment>
          )
        })}
      </div>
      {loading && <LoaderRound size='sm' className='ml-4' />}
      {!!teamActivitiesLeft && (
        <>
          <IconButton
            className='mx-2 size-8 rounded-full p-0 text-sm'
            variant='contained'
            palette='tertiary'
            data-track='Team activities / Expand'
            onClick={fetchMoreActivities}
            aria-label={t`Fetch more activities`}
          >
            +{teamActivitiesLeft}
          </IconButton>
          <Button
            className='rounded'
            palette='tertiary'
            variant='ghost'
            data-track='Team activities / Expand'
            onClick={fetchMoreActivities}
          >
            <Trans>Show older</Trans>
          </Button>
        </>
      )}
    </div>
  )
}
