import { useCallback } from 'react'
import { useUpdateEventReadStatusMutation } from '@graphqlOperations'

export const useSetEventRead = (id: string) => {
  const [update] = useUpdateEventReadStatusMutation()

  return useCallback(
    async (nextRead: boolean) =>
      await update({
        variables: {
          events: [id],
          read: nextRead
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateEventReadStatus: [
            {
              id,
              read: nextRead,
              __typename: 'Event'
            }
          ]
        }
      }),
    [id]
  )
}
