import { useReactiveVar } from '@apollo/client'
import { Trans } from '@lingui/macro'
import { cn, IconButton, IconChat, IconCross, IconSend, LinkButton, Typography } from '@strise/midgard'
import { chatUnreadCount, openChat, showFallback } from '@utils/intercom'
import * as React from 'react'
import { CHAT_Z_INDEX } from '@utils/zIndexes'

export const ChatBubble: React.FC<{
  expanded: boolean
}> = ({ expanded }) => {
  const unreadCount = useReactiveVar(chatUnreadCount)

  return (
    <IconButton
      className={cn(
        'relative mx-1 flex h-sub-header justify-start overflow-hidden rounded-[30px] p-4 shadow-[-1px_0px_28px_#88A7FF]',
        expanded ? 'w-auto' : 'w-[theme(height.sub-header)]'
      )}
      asChild
      variant='contained'
      palette='primary'
      onClick={() => openChat()}
      data-track='Menu / Chat'
    >
      <div>
        {Boolean(unreadCount) && (
          <Typography
            className='absolute right-0 top-0 flex size-[18px] items-center justify-center rounded-full bg-semantic-danger-main'
            variant='body2'
          >
            {unreadCount}
          </Typography>
        )}

        <IconChat className='shrink-0' />
        {expanded && (
          <Typography className='ml-2 whitespace-nowrap' component='span' variant='aLabelBold'>
            <Trans>Message us</Trans>
          </Typography>
        )}
      </div>
    </IconButton>
  )
}

export const ChatFallback = () => {
  const show = useReactiveVar(showFallback)

  if (!show) return null

  return (
    <div
      className='fixed bottom-20 right-20 flex w-[350px] flex-col rounded-md bg-secondary-shade-90 p-4 text-white shadow-md'
      style={{ zIndex: CHAT_Z_INDEX }}
    >
      <div className='flex'>
        <Typography variant='subtitle1' component='span'>
          <Trans>Contact us</Trans>
        </Typography>
        <IconButton
          className='ml-auto rounded bg-secondary-shade-70'
          variant='ghost'
          palette='secondary'
          onClick={() => showFallback(false)}
          data-track='Fallback chat / Close'
        >
          <IconCross />
        </IconButton>
      </div>
      <Typography className='py-8' variant='body1'>
        <Trans>
          We had an issue loading our chat service to you. Instead you can email us at: <b>support@strise.ai</b>
        </Trans>
      </Typography>
      <LinkButton
        className='flex content-between'
        href='mailto:support@strise.ai'
        variant='contained'
        palette='primary'
        endIcon={<IconSend />}
      >
        <Trans>Send email</Trans>
      </LinkButton>
    </div>
  )
}
