import * as React from 'react'
import { cn } from '../utils/className'
import { Typography } from './Typography'

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  animationHover?: boolean
}

const Card = React.forwardRef<HTMLDivElement, CardProps>(({ animationHover, className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'flex flex-col justify-between rounded-md border border-gray-10',
      animationHover && 'transition delay-100 duration-200 ease-in-out hover:shadow-md',
      className
    )}
    {...props}
  />
))
Card.displayName = 'Card'

const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('flex flex-col space-y-1.5 px-4 pt-6', className)} {...props} />
  )
)
CardHeader.displayName = 'CardHeader'

const CardTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => <div ref={ref} className={cn('text-text-primary', className)} {...props} />
)
CardTitle.displayName = 'CardTitle'

const CardDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({ className, ...props }, ref) => (
    <Typography ref={ref} variant='body1' className={cn('text-gray-60', className)} {...props} />
  )
)
CardDescription.displayName = 'CardDescription'

const CardContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('flex flex-col justify-between rounded-b-xl px-4 py-6', className)} {...props} />
  )
)
CardContent.displayName = 'CardContent'

const CardFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('flex h-12 items-center rounded-b-md p-4', className)} {...props} />
  )
)
CardFooter.displayName = 'CardFooter'

export { Card, CardHeader, CardFooter, CardTitle, CardDescription, CardContent }
