import * as React from 'react'
import styled from 'styled-components'
import { handleStyleProps } from '../styleFunctions'
import { sx } from '../styleFunctions/sx'
import { type StyleProps, type SxProps } from '../styleFunctions/StyleFunction'
import { type DataProps } from '@strise/react-utils'

const StyledBox = styled.div.withConfig({
  // @ts-expect-error
  shouldForwardProp: (prop: string) => !handleStyleProps.filterProps.includes(prop)
})`
  ${sx(handleStyleProps)}
`
/** @deprecated - use DivProps */
export interface BoxProps extends Omit<React.HTMLAttributes<HTMLElement>, 'onChange' | 'color'>, StyleProps, DataProps {
  active?: boolean
  children?: React.ReactNode | React.ReactNode[] | null | false | string
  component?: React.ElementType
  palette?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: React.Ref<any>
  sx?: SxProps
  variant?: string
}

/** @deprecated - use div with tailwind */
export const Box = React.forwardRef(({ component, ...props }: BoxProps, ref: React.Ref<unknown>) => {
  return <StyledBox ref={ref} as={component} {...props} />
})
