import { type SidepanelTab } from '@strise/strise-utils'
import React, { useState } from 'react'
import { type DivProps } from '@strise/react-utils'
import { SidepanelEditableCard } from './SidepanelEditableCard'
import { Button, cn, IconButton, IconPenBox, IconPlus, Tag, Typography } from '@strise/midgard'
import { t, Trans } from '@lingui/macro'
import { Table, TableCell, TableRow } from '@strise/system'
import {
  AddEditEntityCounterpartyDialog,
  DeleteCounterpartyDialog,
  type EntityCounterpartyFormData
} from '@components/Counterparty/AddEditEntityCounterpartyDialog'
import { type CounterPartyFragment, type BaseEntityLikeFragment } from '@graphqlTypes'
import {
  useCounterpartiesQuery,
  useCreateCounterPartyMutation,
  useDeleteCounterPartyMutation,
  useUpdateCounterPartyMutation
} from '@graphqlOperations'
import { EntityLink } from '@components/EntityLink/EntityLink'
import { CounterPartyNature } from '@strise/types'
import { formatDate, toast } from '@strise/europa'
import { type ApolloCache } from '@apollo/client'
import { TestIDs } from '@utils/testIDs'

interface SidepanelCounterpartiesCardProps extends DivProps {
  entity: BaseEntityLikeFragment
  sidepanelTab: SidepanelTab
}

const updateCache = (cache: ApolloCache<object>, counterpartyEntity: string | null) => {
  if (!counterpartyEntity) return

  cache.modify({
    id: cache.identify({ __typename: 'Company', id: counterpartyEntity }),
    fields: {
      counterparties(_, { DELETE }) {
        return DELETE
      }
    }
  })
}

const CounterpartiesRow: React.FC<{
  editMode: boolean
  entity: BaseEntityLikeFragment
  node: CounterPartyFragment
  onEditClick: () => void
  striped: boolean
}> = ({ editMode, entity, node, onEditClick, striped }) => {
  return (
    <TableRow className='flex justify-between align-top' striped={striped}>
      <TableCell size='medium'>
        {/* since the link is bidirectional, we want to only expose the linked to entity */}
        <EntityLink entity={entity.id === node.counterPartyEntity.id ? node.entity : node.counterPartyEntity} />
        {!!node.comment && (
          <Typography variant='body1' className='pb-2 text-gray-50'>
            "{node.comment}"
          </Typography>
        )}
        <div className='flex text-left'>
          <Tag palette='blue'>
            <Typography className='text-sm'>Edited</Typography>
          </Tag>
          <Typography variant='aLabelSmall' className='content-center pl-2 text-secondary-shade-50'>
            by {node.createdBy.name} · {formatDate(node.lastModified)}
          </Typography>
        </div>
      </TableCell>
      {editMode && (
        <IconButton
          className={cn('size-10 text-gray-60')}
          variant='ghost'
          onClick={onEditClick}
          data-track='Counterparties / Edit counterparty'
        >
          <IconPenBox size='md' />
        </IconButton>
      )}
    </TableRow>
  )
}

export const SidepanelCounterpartiesCard = React.forwardRef<HTMLDivElement, SidepanelCounterpartiesCardProps>(
  ({ className, entity, sidepanelTab, ...props }, ref) => {
    const [editMode, setEditMode] = useState(false)
    const [showOriginal, setShowOriginal] = useState(false)
    const [counterpartyDialogOpen, setCounterpartyDialogOpen] = React.useState(false)
    const [counterpartyDeleteDialogOpen, setCounterpartyDeleteDialogOpen] = React.useState(false)
    const [counterpartyFormData, setCounterpartyFormData] = React.useState<EntityCounterpartyFormData>({
      id: '',
      comment: '',
      counterpartyEntity: null,
      entity: entity.id
    })

    const getMutationOptions = (successMessage: string) => ({
      onCompleted: () => {
        toast.success(successMessage)
        handleCounterpartyDialogClose()
      },
      update: (cache: ApolloCache<object>) => updateCache(cache, counterpartyFormData.counterpartyEntity)
    })

    const [addCounterparty, { loading: addLoading }] = useCreateCounterPartyMutation(
      getMutationOptions(t`Counterparty added`)
    )

    const [updateCounterparty, { loading: updateLoading }] = useUpdateCounterPartyMutation(
      getMutationOptions(t`Counterparty updated`)
    )

    const [deleteCounterparty, { loading: deleteLoading }] = useDeleteCounterPartyMutation(
      getMutationOptions(t`Counterparty deleted`)
    )

    const { data, loading } = useCounterpartiesQuery({
      variables: { company: entity.id }
    })

    const counterparties = data?.company.counterParties
    const hasRows = !!data?.company.counterParties.length

    const handleCounterpartyDialogClose = () => {
      setCounterpartyDialogOpen(false)
      setCounterpartyDeleteDialogOpen(false)
      setCounterpartyFormData({ id: '', comment: '', counterpartyEntity: null, entity: entity.id })
    }

    const handleDeleteCounterparty = async () => {
      await deleteCounterparty({
        variables: {
          counterPartyId: counterpartyFormData.id,
          company: entity.id
        }
      })
    }

    const handleUpdateCounterparty = async () => {
      await updateCounterparty({
        variables: {
          counterPartyId: counterpartyFormData.id,
          comment: counterpartyFormData.comment,
          company: counterpartyFormData.entity || ''
        }
      })
    }

    const handleAddCounterparty = async () => {
      if (!counterpartyFormData.counterpartyEntity) return

      await addCounterparty({
        variables: {
          input: {
            comment: counterpartyFormData.comment,
            entity: entity.id,
            counterPartyEntity: counterpartyFormData.counterpartyEntity,
            nature: CounterPartyNature.Other
          },
          entity: entity.id
        }
      })
    }

    const counterpartiesContent = () => {
      if (!hasRows && !loading) {
        return (
          <Typography variant='body1' className='pb-4 text-center text-secondary-shade-40'>
            <Trans>No data</Trans>
          </Typography>
        )
      }
      return (
        <div className='flex w-full px-2'>
          <Typography className='mr-3 mt-2 flex w-1/3 shrink-0 flex-col justify-center whitespace-normal text-text-secondary'>
            Counterparties
          </Typography>
          <Table>
            <tbody>
              {counterparties?.map((counterparty, index) => {
                return (
                  <CounterpartiesRow
                    node={counterparty}
                    key={index}
                    striped={hasRows ? index === counterparties.length - 1 : false}
                    entity={entity}
                    editMode={editMode}
                    onEditClick={() => {
                      setCounterpartyDialogOpen(true)
                      setCounterpartyFormData({
                        id: counterparty.id,
                        comment: counterparty.comment || '',
                        counterpartyEntity: counterparty.counterPartyEntity.id,
                        entity: entity.id
                      })
                    }}
                  />
                )
              })}
            </tbody>
          </Table>
        </div>
      )
    }

    return (
      <div className={className} {...props} data-id={TestIDs.SidePanel.Counterparty.root}>
        <SidepanelEditableCard
          ref={ref}
          tab={sidepanelTab}
          title='Counterparties'
          loading={loading}
          setEditMode={setEditMode}
          context='Counterparties'
          editMode={editMode}
          editTitle='Edit counterparties'
          hasCustomData={false}
          hasEditAccess={true}
          lastEditedAt={null}
          setShowOriginal={setShowOriginal}
          showOriginal={showOriginal}
          toggleEditButtonDataId={TestIDs.SidePanel.Counterparty.toggleEditButton}
        >
          <div className='w-full p-2'>{counterpartiesContent()}</div>
          <Button
            className={editMode ? 'w-full pb-2' : 'hidden'}
            variant='ghost'
            data-track={`Counterparties / ${entity.name} / Add counterparty`}
            data-id={TestIDs.SidePanel.Counterparty.addCounterpartyCardButton}
            onClick={() => setCounterpartyDialogOpen(true)}
          >
            <IconPlus className='pr-2' />
            Add counterparty
          </Button>
        </SidepanelEditableCard>
        {counterpartyDialogOpen && (
          <AddEditEntityCounterpartyDialog
            formData={counterpartyFormData}
            entity={entity}
            onClose={handleCounterpartyDialogClose}
            handleConfirm={counterpartyFormData.id ? handleUpdateCounterparty : handleAddCounterparty}
            loading={updateLoading || addLoading}
            setFormData={setCounterpartyFormData}
            onCounterpartyDelete={() => setCounterpartyDeleteDialogOpen(true)}
          />
        )}
        {counterpartyDeleteDialogOpen && (
          <DeleteCounterpartyDialog
            counterpartyEntityId={counterpartyFormData.counterpartyEntity || ''}
            handleCancel={() => setCounterpartyDeleteDialogOpen(false)}
            handleDeletion={handleDeleteCounterparty}
            loading={deleteLoading}
          />
        )}
      </div>
    )
  }
)
