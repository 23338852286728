import * as Sentry from '@sentry/react'

const init = () => {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT,
    tunnel: process.env.SENTRY_TUNNEL,
    denyUrls: [/api=[A-Z]+/],
    ignoreErrors: [
      /ResizeObserver loop limit exceeded/,
      /ResizeObserver loop completed with undelivered notifications/
    ],
    integrations: [
      Sentry.captureConsoleIntegration({
        levels: ['error']
      })
    ]
  })
}

export const sentry = {
  init
}
