import { cn, ExternalLink, Markdown, Typography } from '@strise/midgard'
import { useCurrentUserFeatures } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useTeam } from '@contexts/TeamContext/TeamContext'
import {
  useEntityEventHeaderQuery,
  useEventAiSummaryQuery,
  useEventMetadataQuery,
  useEventWithAnnotationsQuery
} from '@graphqlOperations'
import { type AnnotationFragment } from '@graphqlTypes'
import { Trans } from '@lingui/macro'
import {
  EventAiSummaryLoader,
  EventDialogBodyLoader,
  EventDialogHeaderLoader,
  EventDialogMetadataLoader
} from '@loaders/EventDialogLoader'
import { Annotate, type AnnotationProps, formatDate, Img, type Offset, useIsSupervisor } from '@strise/europa'
import { omniEventUrl } from '@strise/fika'
import { Dropdown, Modal, ModalContent, ModalHeader } from '@strise/system'
import { type DivProps } from '@strise/react-utils'
import { TrackedActivityKind } from '@strise/types'
import { track, useTrackOnLoad } from '@utils/tracking'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { EntityLink } from '../EntityLink/EntityLink'
import { EntityIdMeta } from '../EntityMeta/EntityMeta'
import { PublisherLink } from '../EventLink'
import { EventLikeDislike } from './EventLikeDislike'
import { prefixPaywallTitle } from './eventUtils'
import { useSetEventRead } from './eventsHooks'
import { useDisplayLanguage } from '@contexts/DisplayLanguageContext/displayLanguageContextUtils'
import { useEventDialog } from '@components/Events/eventDialogUtils'

const annotationToOffset = ({ node: annotation }: { node: AnnotationFragment }): Offset<AnnotationFragment> => ({
  offset: annotation.offset?.offset || 0,
  phrase: annotation.phrase || '',
  data: annotation
})

interface AnnotatedTextProps {
  annotations: Array<{ node: AnnotationFragment }>
  entityId: string
  isTitle?: boolean
  text: string
}

interface MetadataProps {
  entityId: string
  eventId: string
  mentions: string[]
}

const EventDialogHeader = ({ entityId, handleClose }: { entityId: string; handleClose: () => void }) => {
  const { data } = useEntityEventHeaderQuery({
    variables: { id: entityId }
  })
  const entity = data?.entity
  if (!entity) return <EventDialogHeaderLoader />
  return (
    <ModalHeader onClose={handleClose}>
      <EntityLink entity={entity} className='whitespace-nowrap text-inherit' noFlags noLifeStatus noTooltip>
        {entity.name}
      </EntityLink>
    </ModalHeader>
  )
}

const AnnotateContent = ({
  data,
  element,
  entityId
}: {
  data: AnnotationFragment | undefined
  element: React.ReactNode
  entityId: string
}) => {
  const markedText = (
    <mark
      className={cn(
        'bg-transparent',
        data?.annotated.id === entityId
          ? 'shadow-[inset_0_-5px_0_0_theme(colors.accent.orange.shade.30)]'
          : 'shadow-[inset_0_-5px_0_0_theme(colors.accent.green.shade.30)]'
      )}
    >
      {element}
    </mark>
  )

  const isCompany = data?.annotated.__typename === 'Company'

  if (!isCompany) return markedText

  const content = <EntityIdMeta id={data.annotated.id} variant='small' link />

  return (
    <Dropdown
      component='span'
      trigger='hover'
      ToggleComponent={markedText}
      paperProps={{ className: 'p-2' }}
      popperProps={{ placement: 'bottom', strategy: 'fixed' }}
    >
      {content}
    </Dropdown>
  )
}

const AnnotatedText = ({
  annotations,
  className,
  entityId,
  isTitle,
  text,
  ...props
}: AnnotatedTextProps & DivProps) => {
  const typename = isTitle ? 'TitleOffset' : 'BodyOffset'

  const offsets = annotations.filter(({ node }) => node.offset?.__typename === typename).map(annotationToOffset)

  return (
    <Annotate className={className} text={text} offsets={offsets} {...props}>
      {({ data, element, index }: AnnotationProps<AnnotationFragment>) => {
        return <AnnotateContent key={index} entityId={entityId} element={element} data={data} />
      }}
    </Annotate>
  )
}

const EventAiSummary = ({ entityId, eventId, mentions }: MetadataProps) => {
  const language = useDisplayLanguage()

  const { data, error, loading } = useEventAiSummaryQuery({
    variables: {
      id: eventId,
      summaryContext: [entityId, ...mentions],
      language
    }
  })

  return (
    <>
      <Typography className='text-primary-main' component='h2' variant='subtitle1'>
        <Trans>AI summary</Trans>
      </Typography>
      {loading ? (
        <EventAiSummaryLoader />
      ) : (
        <div className='mb-10'>
          {data && <Markdown defaultVariant='body2'>{data.event.summary.text}</Markdown>}
          {error && (
            <Typography className='text-semantic-danger-main' variant='body2'>
              {error.message}
            </Typography>
          )}
        </div>
      )}
    </>
  )
}

const MetadataColumn = ({ entityId, eventId, mentions }: MetadataProps) => {
  const isSupervisor = useIsSupervisor()
  const features = useCurrentUserFeatures()

  const { data, loading } = useEventMetadataQuery({
    variables: {
      id: eventId,
      first: 5,
      entityId,
      summaryContext: [entityId, ...mentions]
    }
  })

  if (loading) {
    return <EventDialogMetadataLoader />
  }

  const event = data?.event
  if (!event) {
    return null
  }

  const similarNonStrisePublishedEvents = event.similar.edges.filter((e) => !e.node.isPublishedByStrise)

  return (
    <div className='sticky top-0 self-start pt-6 legacy-xs:w-full legacy-md:w-4/12 legacy-lg:w-3/12'>
      <div className='mb-4 w-16'>
        <EventLikeDislike className='flex-row' entityId={entityId} event={event} relevance={event.relevant} />
      </div>

      {features.EVENT_AI_SUMMARY ? (
        <EventAiSummary eventId={eventId} entityId={entityId} mentions={mentions} />
      ) : (
        <div>
          <Typography className='text-primary-main' component='h2' variant='subtitle1'>
            <Trans>Strise summary</Trans>
          </Typography>

          <Typography className='mb-10' variant='body2'>
            {event.summary.text}
          </Typography>
        </div>
      )}

      {!event.isPublishedByStrise && (
        <div className={event.similar.edges.length ? 'mb-5' : undefined}>
          <Typography variant='body2'>
            <Trans>Reported by</Trans>
          </Typography>

          <PublisherLink className='text-secondary-main' href={event.url}>
            {event.publisher}
          </PublisherLink>
        </div>
      )}

      {!!similarNonStrisePublishedEvents.length && (
        <div className='mb-5'>
          <Typography variant='body2'>
            <Trans>Also reported by</Trans>
          </Typography>
          {similarNonStrisePublishedEvents.map(({ node: similar }) => (
            <PublisherLink key={similar.id} href={similar.url} className='text-secondary-main'>
              {similar.publisher}
            </PublisherLink>
          ))}
        </div>
      )}

      {event.similar.pageInfo.totalCount > similarNonStrisePublishedEvents.length && (
        <Typography variant='body2'>
          and {event.similar.pageInfo.totalCount - event.similar.edges.length} more
        </Typography>
      )}

      {isSupervisor && (
        <Typography className='mt-10' variant='body2'>
          <ExternalLink href={omniEventUrl(event.id)}>Omni link</ExternalLink>
        </Typography>
      )}
    </div>
  )
}

const EventColumn = ({ entityId, eventId, mentions }: { entityId: string; eventId: string; mentions: string[] }) => {
  const { portfolioId } = useTeam()
  const { data, loading } = useEventWithAnnotationsQuery({
    variables: {
      id: eventId,
      entityFilter: [entityId, ...mentions],
      portfolioFilter: [portfolioId]
    }
  })
  const setRead = useSetEventRead(eventId)

  const event = data?.event
  const eventSource = event?.publisher

  useEffect(() => {
    if (event && !event.read) setRead(true)
  }, [!event])

  const trackPaywallLink = track.bind(null, TrackedActivityKind.StriseEventUrlClicked)
  useTrackOnLoad(TrackedActivityKind.StriseEventViewed, { entityId, eventId, eventSource }, !event || loading)

  if (!event || loading) return <EventDialogBodyLoader />
  const annotations = event.annotations.edges

  return (
    <div className='legacy-xs:w-full legacy-xs:px-0 legacy-md:w-8/12 legacy-md:px-6 legacy-lg:w-9/12 legacy-lg:px-[3.75rem]'>
      <Helmet>
        <title>{prefixPaywallTitle(event)}</title>
      </Helmet>
      <div className='max-w-[600px]'>
        <Typography className='mb-2' variant='body2'>
          {formatDate(event.published, { relative: false })}
        </Typography>
        <Typography className='mb-8' component='h1' variant='h3'>
          {event.behindPaywall && '(+) '}
          <AnnotatedText className='w-fit' isTitle text={event.title} annotations={annotations} entityId={entityId} />
        </Typography>
        <Typography className='mb-8 block' component='p' variant='subtitle1'>
          {event.summary.text}
        </Typography>
        <Typography className='whitespace-pre-wrap pb-10' component='div' variant='body1'>
          <AnnotatedText text={event.body} annotations={annotations} entityId={entityId} />
        </Typography>
        {event.shouldRedirect && (
          <div className='-mt-2 mb-8'>
            <ExternalLink
              variant='body1'
              className='text-text-link'
              href={event.url}
              data-track='false'
              onClick={() =>
                trackPaywallLink({
                  publisher: event.publisher,
                  url: event.url,
                  paywall: event.behindPaywall
                })
              }
            >
              <Trans>Read full article on {event.publisher}</Trans>
            </ExternalLink>
          </div>
        )}

        <div className='mb-5 flex overflow-auto'>
          {event.images.map(({ url }, i) => {
            return <Img key={i} className='mr-4 h-[200px]' src={url} />
          })}
        </div>
      </div>
    </div>
  )
}

export const EventDialog = () => {
  const { entityId, eventId, mentions, reset } = useEventDialog()

  if (!eventId || !entityId) return null

  const handleClose = () => {
    reset()
  }

  return (
    <Modal isOpen onRequestClose={handleClose}>
      <EventDialogHeader entityId={entityId} handleClose={handleClose} />
      <ModalContent>
        <div className='flex flex-wrap legacy-xs:px-0 legacy-xs:py-4 legacy-lg:p-20'>
          <EventColumn eventId={eventId} entityId={entityId} mentions={mentions ?? []} />
          <MetadataColumn eventId={eventId} entityId={entityId} mentions={mentions ?? []} />
        </div>
      </ModalContent>
    </Modal>
  )
}
