import {
  useConflictsQuery,
  useResolveOwnershipConflictsMutation,
  useResolveRoleConflictMutation
} from '@graphqlOperations'
import { t, Trans } from '@lingui/macro'
import { toast, useRecursiveQueryParamsReactRouter, validateString } from '@strise/europa'
import { Alert, Button, cn, Divider, IconButton, IconCross, IconDotSmall, IconInfo, Typography } from '@strise/midgard'
import { Dialog, Modal } from '@strise/system'
import * as React from 'react'
import { useState } from 'react'
import { ConflictCard } from './ConflictCard'
import { RoleConflictSelectionKind } from '@strise/types'
import { SkeletonLoader } from './SkeletonLoader'
import {
  ConflictKind,
  getConflictKindDataTrackBaseString,
  getConflictPromptBodyText,
  getConflictPromptResolveActionText,
  getConflictPromptTitleText,
  resetOwnershipChartCache,
  resetRoleTableCache,
  transformConflicts,
  type TransformedConflict
} from '@components/Conflicts/conflictUtils'
import { OwnershipAbove25Toggle } from '@components/Ownerships/OwnershipAbove25Toggle'
import { type ApolloCache, useReactiveVar } from '@apollo/client'
import { refreshReviewCompanyMap } from '@state'
import { useEntityLikeName } from '@utils/entity'
import { conflictIdQueryParamKey } from '@strise/strise-utils'

interface ConflictModalQueryParamsState {
  [conflictIdQueryParamKey]: string | undefined
}

const validations = {
  [conflictIdQueryParamKey]: validateString
}

// TODO: Temporary props for usage in Storybook - should be replaced with https://storybook.js.org/addons/@storybook/addon-queryparams
export const ConflictsModal = ({ entityId }: { entityId?: string }) => {
  const [modalState, setModalState] = useRecursiveQueryParamsReactRouter<ConflictModalQueryParamsState>(
    { [conflictIdQueryParamKey]: entityId },
    validations
  )

  if (!modalState[conflictIdQueryParamKey]) return null

  const handleClose = () => {
    setModalState({ [conflictIdQueryParamKey]: undefined })
  }

  return (
    <Modal
      isOpen
      shouldCloseOnEsc
      onRequestClose={handleClose}
      contentMaxWidth='calc(100vw - 128px)'
      contentProps={{ className: 'my-10 h-auto bg-gray-10' }}
    >
      <div className='flex items-center justify-between px-4 pb-2 pt-6'>
        <Typography component='h1' variant='aLabel'>
          <Trans>Resolve data conflicts</Trans>
        </Typography>
        <IconButton onClick={handleClose} data-track='Resolve Conflicts / Close'>
          <IconCross />
        </IconButton>
      </div>
      <ConflictsContent handleClose={handleClose} entityId={modalState[conflictIdQueryParamKey]} />
    </Modal>
  )
}

interface ConflictsContentProps {
  entityId: string
  handleClose: () => void
}

const ConflictsContent = ({ entityId, handleClose }: ConflictsContentProps) => {
  const [activeConflictId, setActiveConflictId] = React.useState<string>()
  const [currentChecked, setCurrentChecked] = React.useState<boolean>(false)
  const [updatedChecked, setUpdatedChecked] = React.useState<boolean>(false)

  const [promptDialogOpen, setPromptDialogOpen] = useState(false)

  const [above25, setAbove25] = useState(false)

  const getConflictSelection = () => {
    if (currentChecked && updatedChecked) {
      return RoleConflictSelectionKind.Both
    }
    if (currentChecked) {
      return RoleConflictSelectionKind.Current
    }
    if (updatedChecked) {
      return RoleConflictSelectionKind.Conflicting
    }
  }

  const entityName = useEntityLikeName(entityId)

  const { data, loading: conflictsLoading } = useConflictsQuery({
    variables: { entity: entityId, resolved: false }
  })

  const resolveSuccessText = t`Conflict resolved!`

  const refreshPrepareReview = useReactiveVar(refreshReviewCompanyMap)
  const refreshReviewForEntity = () => {
    refreshReviewCompanyMap({
      ...refreshPrepareReview,
      [entityId]: (refreshPrepareReview[entityId] ?? 0) + 1
    })
  }

  const [resolveRoleConflict, { loading: resolveRoleConflictsLoading }] = useResolveRoleConflictMutation({
    onCompleted: (resolveData) => {
      toast.success(resolveSuccessText)
      if (resolveData.resolveRoleConflict.company.conflicts.length === 0) {
        handleClose()
      }
      // Refresh any active review on this company
      // Changes to the roles will impact what we display in review
      refreshReviewForEntity()
    },
    update: (cache: ApolloCache<object>) => resetRoleTableCache(cache, entityId)
  })

  const [resolveOwnershipConflict, { loading: resolveOwnershipConflictLoading }] = useResolveOwnershipConflictsMutation(
    {
      onCompleted: (resolveData) => {
        toast.success(resolveSuccessText)
        if (resolveData.resolveOwnershipConflicts.company.conflicts.length === 0) {
          handleClose()
        }
        // Refresh any active review on this company
        // Changes to the owners will impact what we display in review
        refreshReviewForEntity()
      },
      update: (cache: ApolloCache<object>) => resetOwnershipChartCache(cache, entityId)
    }
  )

  const conflicts = data?.company.conflicts
  const transformedConflicts = transformConflicts(conflicts, entityId)

  const activeConflictIndex = activeConflictId
    ? transformedConflicts.findIndex((conflict) => conflict.id === activeConflictId)
    : 0
  const activeConflict = transformedConflicts[activeConflictIndex]
  const nextConflictId = conflicts?.[activeConflictIndex + 1]?.id

  const handleSetCurrentChecked = (checked: boolean) => {
    setCurrentChecked(checked)
    if (activeConflict?.kind === ConflictKind.Ownership && updatedChecked && checked) {
      setUpdatedChecked(false)
    }
  }

  const handleSetUpdatedChecked = (checked: boolean) => {
    setUpdatedChecked(checked)
    if (activeConflict?.kind === ConflictKind.Ownership && currentChecked && checked) {
      setCurrentChecked(false)
    }
  }

  const handleClosePrompt = () => {
    setPromptDialogOpen(false)
  }

  const handleResolve = async () => {
    handleClosePrompt()
    const selection = getConflictSelection()
    if (activeConflict && selection) {
      if (activeConflict.kind === ConflictKind.Role) {
        await resolveRoleConflict({
          variables: {
            input: {
              id: activeConflict.id,
              selection
            },
            entity: entityId,
            resolved: false
          }
        })
        setCurrentChecked(false)
        setUpdatedChecked(false)
      }
      // Can't select both for Ownership conflicts (also blocked by disabling the opposing checkbox)
      if (activeConflict.kind === ConflictKind.Ownership && selection !== RoleConflictSelectionKind.Both) {
        await resolveOwnershipConflict({
          variables: {
            input: {
              conflicts: activeConflict.conflicts.map((conflict) => conflict.id),
              selection
            },
            entity: entityId,
            resolved: false
          }
        })
        setCurrentChecked(false)
        setUpdatedChecked(false)
      }
    }
    if (nextConflictId) {
      setActiveConflictId(nextConflictId)
    }
  }

  if (conflictsLoading) {
    return <SkeletonLoader />
  }

  const multipleConflicts = transformedConflicts.length > 1

  return (
    <>
      {multipleConflicts && <Divider className='bg-gray-20' />}
      <div className={cn('flex min-h-[600px]', { 'mx-4': !multipleConflicts })}>
        {multipleConflicts && (
          <div className='overflow-auto border-r border-gray-20 p-4'>
            <Typography variant='aLabelSmall' className='mb-1 inline-block p-2'>
              <Trans>Conflicts ({conflicts?.length})</Trans>
            </Typography>
            <div className='mb-8 flex max-w-56 flex-col gap-3'>
              {transformedConflicts.map((conflict, index) => {
                return (
                  <ConflictButton
                    key={conflict.id}
                    conflict={conflict}
                    isActive={index === activeConflictIndex}
                    onClick={setActiveConflictId}
                  />
                )
              })}
            </div>
          </div>
        )}
        <div className='flex w-full flex-col'>
          <div className='grow border-gray-20 bg-white'>
            <div className='flex h-full flex-col'>
              {transformedConflicts.length === 0 && (
                <>
                  {/* TODO: Reuse this within active conflicts as well */}
                  <div className='flex items-center justify-between border-b border-gray-20 px-4 py-3'>
                    <Typography variant='aLabelBold' className='inline-block'>
                      {entityName}
                    </Typography>
                  </div>
                  <div className='flex h-full flex-col items-center justify-center'>
                    <Typography>
                      <b>
                        <Trans>You are up to date!</Trans>
                      </b>
                    </Typography>
                    <Typography>
                      <Trans>There are no current data conflicts for this entity.</Trans>
                    </Typography>
                  </div>
                </>
              )}
              {activeConflict && (
                <>
                  {activeConflict.kind === ConflictKind.Ownership && (
                    <Alert
                      variant='info'
                      className='w-full px-4 py-3'
                      textProps={{ className: 'text-sm' }}
                      icon={IconInfo}
                    >
                      <Trans>
                        Replace and archive previously edited data, or keep and apply the edits to the new official data
                      </Trans>
                    </Alert>
                  )}
                  <div className='flex items-center justify-between px-4 py-3'>
                    <Typography variant='aLabelBold' className='inline-block'>
                      {activeConflict.entity.name}{' '}
                      {activeConflict.conflicts.length > 1 && `(${activeConflict.conflicts.length})`}
                    </Typography>

                    {activeConflict.kind === ConflictKind.Ownership && (
                      <OwnershipAbove25Toggle
                        className='justify-end self-end pr-4'
                        above25={above25}
                        setAbove25={setAbove25}
                        edit={false}
                      />
                    )}
                  </div>
                  <div className='grid h-full grid-cols-2'>
                    <ConflictCard
                      conflict={activeConflict}
                      checked={updatedChecked}
                      setChecked={handleSetUpdatedChecked}
                      above25={above25}
                    />
                    <ConflictCard
                      conflict={activeConflict}
                      isCustom
                      checked={currentChecked}
                      setChecked={handleSetCurrentChecked}
                      above25={above25}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className='flex h-20 shrink items-center justify-between bg-gray-10 p-4'>
            {activeConflict && (
              <div className='flex w-full items-center justify-end gap-5'>
                <Typography className='text-text-secondary'>
                  {activeConflict.kind === ConflictKind.Role && (
                    <Trans>Keep the current edited role, the official role, or both</Trans>
                  )}
                  {activeConflict.kind === ConflictKind.Ownership && (
                    <Trans>Keep or archive the edited ownership data</Trans>
                  )}
                </Typography>
                <Button
                  onClick={handleClose}
                  palette='tertiary'
                  variant='outlined'
                  className='border-gray-20'
                  data-track={`${getConflictKindDataTrackBaseString(activeConflict.kind)} / cancel`}
                >
                  <Trans>Cancel</Trans>
                </Button>
                <Button
                  disabled={!currentChecked && !updatedChecked}
                  onClick={() => setPromptDialogOpen(true)}
                  palette='primary'
                  variant='contained'
                  data-track={`${getConflictKindDataTrackBaseString(activeConflict.kind)} / submit`}
                  loading={resolveRoleConflictsLoading || resolveOwnershipConflictLoading}
                >
                  <Trans>Save</Trans>
                </Button>
              </div>
            )}
          </div>
        </div>
        {promptDialogOpen && activeConflict?.entity.name && (
          <Dialog
            isOpen
            title={getConflictPromptTitleText(activeConflict.kind, getConflictSelection())}
            body={getConflictPromptBodyText(activeConflict.kind, activeConflict.entity.name)}
            shouldCloseOnOverlayClick
            shouldCloseOnEsc
            onRequestClose={handleClosePrompt}
          >
            <div className='flex w-full'>
              <Button
                data-track={`${getConflictKindDataTrackBaseString(activeConflict.kind)} / prompt / cancel`}
                variant='contained'
                className='flex-1'
                onClick={handleClosePrompt}
              >
                Cancel
              </Button>
              <Button
                data-track={`${getConflictKindDataTrackBaseString(activeConflict.kind)} / prompt / resolve`}
                onClick={handleResolve}
                variant='contained'
                className='flex-1'
                palette='primary'
              >
                {getConflictPromptResolveActionText(getConflictSelection())}
              </Button>
            </div>
          </Dialog>
        )}
      </div>
    </>
  )
}

interface ConflictButtonProps {
  conflict: TransformedConflict
  isActive: boolean
  isResolved?: boolean
  onClick: (conflictId: string) => void
}

const ConflictButton = ({ conflict, isActive, isResolved, onClick }: ConflictButtonProps) => {
  if (conflict.kind === ConflictKind.Unknown) return null

  return (
    <button
      className={cn('flex items-baseline gap-1 rounded p-2', isActive && 'bg-white')}
      key={conflict.id}
      onClick={() => onClick(conflict.id)}
      disabled={isResolved}
    >
      <Typography
        className={cn('truncate leading-5', isResolved && 'text-text-secondary')}
        variant={isActive ? 'aLabelBold' : 'aLabel'}
      >
        {conflict.entity.name}
      </Typography>
      {conflict.conflicts[0]?.__typename === 'RoleConflictV2' && (
        <Typography variant='aLabelSmall' className='truncate text-text-secondary'>
          {conflict.conflicts[0]?.customPart?.roleMeta?.roleTitle}
        </Typography>
      )}
      {conflict.kind === ConflictKind.Ownership && (
        <Typography variant='aLabelSmall'>({conflict.conflicts.length})</Typography>
      )}
      <IconDotSmall
        className={cn(
          'ml-auto shrink-0 self-center',
          isResolved ? 'text-semantic-success-main' : 'text-semantic-danger-main'
        )}
        size='md'
      />
    </button>
  )
}
