import React, { useState } from 'react'
import { type BaseEntityLikeFragment, type EntityBusinessRoleMetaFragment, type TableFragment } from '@graphqlTypes'
import { type DivProps } from '@strise/react-utils'
import { Button, Divider, IconPlusSmall, Label, Switch, Typography } from '@strise/midgard'
import { t, Trans } from '@lingui/macro'
import { useTransformedTable } from '@utils/apiTable/apiTableValueBaseUtils'
import { SidepanelTransformedTableRow } from '@components/Sidepanel/SidepanelTransformedTableRow'
import { SidepanelEditableCard } from '@components/Sidepanel/SidepanelCards/SidepanelEditableCard'
import { EditRoleDialog } from '@components/Roles/EditRoleDialog'
import { type TimespanInput } from '@strise/types'
import { type EditContext, type handleEditRowFn } from '@utils/apiTable/apiTableUtils'
import { useBusinessRolesQuery } from '@graphqlOperations'
import { type SupportedSidepanelEntityFragment } from '@components/Sidepanel/utils/sidepanelUtils'
import { type SidepanelTab } from '@strise/strise-utils'
import { extractIsGlobalEntity } from '@strise/europa'
import { useReactiveVar } from '@apollo/client'
import { refreshReviewCompanyMap } from '@state'
import { ResetRoles } from '@components/Roles/ResetRoles'
import { useCurrentUserFeatures } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { TestIDs } from '@utils/testIDs'
import { ConflictAlert } from '@components/Conflicts/ConflictAlert'
import { ConflictAlertType } from '@components/Conflicts/ConflictAlertType'
import { useUnresolvedConflicts } from '@components/Conflicts/useHasUnresolvedConflicts'

const extractRoleTable = (entity: EntityBusinessRoleMetaFragment | null): TableFragment | null => {
  return entity && 'roleTableV2' in entity ? entity.roleTableV2 : null
}

interface SidepanelRolesCardProps extends DivProps {
  entity: SupportedSidepanelEntityFragment
  sidepanelTab: SidepanelTab
}

export interface RoleFormData {
  comment?: string | null
  entity: BaseEntityLikeFragment | null
  period: TimespanInput | null
  roleTitleId?: string | null
}

export const SidepanelRolesCard = React.forwardRef<HTMLDivElement, SidepanelRolesCardProps>(
  ({ className, entity, sidepanelTab, ...props }, ref) => {
    const features = useCurrentUserFeatures()
    const hasAccess = !!features.EDIT_ROLES
    const refreshPrepareReview = useReactiveVar(refreshReviewCompanyMap)
    const [isExistingRole, setIsExistingRole] = useState(false)
    const [showHistoric, setShowHistoric] = useState(true)
    const filterActive = showHistoric ? undefined : true
    const [showOriginal, setShowOriginal] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const { data, loading } = useBusinessRolesQuery({
      variables: {
        entity: entity.id,
        filterActive,
        ignoreCustomRoles: showOriginal
      },
      fetchPolicy: 'cache-and-network'
    })

    const { data: customData, refetch: refetchHasCustom } = useBusinessRolesQuery({
      variables: {
        entity: entity.id,
        ignoreCustomRoles: false,
        filterActive
      }
    })

    const { conflictsLoading, hasRoleConflicts } = useUnresolvedConflicts(entity)

    const [editMode, setEditMode] = useState(false)
    const [formData, setFormData] = React.useState<RoleFormData>({
      entity: null,
      period: null
    })

    const refreshReview = (updatedEntity: BaseEntityLikeFragment) => {
      // prepare review again to update ownerships, peps, sanctions, etc
      refreshReviewCompanyMap({
        ...refreshPrepareReview,
        [updatedEntity.id]: (refreshPrepareReview[updatedEntity.id] ?? 0) + 1,
        [entity.id]: (refreshPrepareReview[entity.id] ?? 0) + 1
      })
    }

    const handleEdit: handleEditRowFn = (e: BaseEntityLikeFragment | null, context?: EditContext) => {
      if (context?.type === 'EditRole') {
        setIsExistingRole(!!context.existingRole)

        setFormData((prevState) => ({
          ...prevState,
          entity: e,
          roleTitleId: context.roleTitleId,
          period: context.periodTo || context.periodFrom ? { from: context.periodFrom, to: context.periodTo } : null,
          comment: context.comment
        }))
      }

      setIsDialogOpen(true)
    }

    const handleUpdate = () => {
      if (!formData.entity || !formData.roleTitleId) return
      refreshReview(formData.entity)
      setFormData({
        entity: null,
        period: null,
        comment: null,
        roleTitleId: null
      })
      setIsDialogOpen(false)

      refetchHasCustom()
    }

    const transformedTable = useTransformedTable(entity, extractRoleTable(data?.entity ?? null), editMode, handleEdit)

    const customRoleTable = extractRoleTable(customData?.entity ?? null)

    const hasRows = !!transformedTable?.rows.length

    return (
      <div className={className} {...props} data-id={TestIDs.SidePanel.Roles.root}>
        <SidepanelEditableCard
          ref={ref}
          tab={sidepanelTab}
          editDisabled={conflictsLoading || hasRoleConflicts}
          editMode={editMode}
          editTitle={t`Edit roles`}
          context='Roles'
          setEditMode={setEditMode}
          loading={loading}
          setShowOriginal={setShowOriginal}
          resetContent={<ResetRoles entity={entity} filterActive={filterActive} ignoreCustomRoles={showOriginal} />}
          title={transformedTable?.title ?? t`Roles`}
          hasEditAccess={hasAccess && !extractIsGlobalEntity(entity)}
          lastEditedAt={customRoleTable?.lastModifiedAt ? new Date(customRoleTable.lastModifiedAt) : null}
          showOriginal={showOriginal}
          description={transformedTable?.description}
          hasCustomData={!!customRoleTable?.hasCustomData}
          toggleEditButtonDataId={TestIDs.SidePanel.EditableCard.toggleEditButton}
        >
          <ConflictAlert type={ConflictAlertType.ROLES} entity={entity} />
          <div className='grid w-full p-2'>
            {!loading && (
              <div className='mr-8 flex gap-2 justify-self-end'>
                <Label variant='aLabelSmall' htmlFor='historic-roles-switch'>
                  <Trans>Show historic roles</Trans>
                </Label>
                <Switch id='historic-roles-switch' checked={showHistoric} onCheckedChange={setShowHistoric} />
                <Typography variant='aLabelSmall'>
                  {showHistoric && <Trans>On</Trans>}
                  {!showHistoric && <Trans>Off</Trans>}
                </Typography>
              </div>
            )}
            <div className='px-4'>
              {!hasRows && !loading && (
                <Typography variant='body1' className='text-secondary-shade-40'>
                  <Trans>No data</Trans>
                </Typography>
              )}
              {transformedTable?.rows.map((row, index) => {
                return <SidepanelTransformedTableRow key={index} row={row} hideDivider context='Roles' />
              })}
            </div>
            {!extractIsGlobalEntity(entity) && (
              <EditRoleDialog
                entity={entity}
                isOpen={isDialogOpen}
                handleUpdate={handleUpdate}
                setIsOpen={setIsDialogOpen}
                isExistingRole={isExistingRole}
                setFormData={setFormData}
                formData={formData}
                showHistoric={showHistoric}
              />
            )}
            {editMode && (
              <>
                <Divider />
                <Button
                  data-track='Sidepanel / Roles / Open Add Role'
                  data-id={TestIDs.SidePanel.Roles.addRoleButton}
                  palette='primary'
                  className='m-2 w-fit pl-0'
                  startIcon={<IconPlusSmall />}
                  onClick={() => {
                    setIsDialogOpen(true)
                  }}
                >
                  <Typography>
                    <Trans>Add role</Trans>
                  </Typography>
                </Button>
              </>
            )}
          </div>
        </SidepanelEditableCard>
      </div>
    )
  }
)
