import * as React from 'react'
import { type AnnotationRenderFn, type Command, generateCommands, type Offset } from './annotateUtils'
import { annotateParse } from './annotateParse'
import { orderBy } from 'lodash-es'
import { type DivProps } from '@strise/react-utils'

interface AnnotateProps<D> extends DivProps {
  children: AnnotationRenderFn<D>
  offsets: Array<Offset<D>>
  text: string
}

export const Annotate = <D extends object>({ children, className, offsets, text, ...props }: AnnotateProps<D>) => {
  const urlPattern = /\bhttps?:\/\/\S+/gi
  const matches = text.match(urlPattern)
  const matchesOffsets: Array<Offset<D>> =
    matches?.map((match) => {
      return {
        offset: text.indexOf(match),
        phrase: match,
        link: true
      }
    }) ?? []

  const offsetsWithLinks = orderBy([...offsets, ...matchesOffsets], (offset) => [offset.offset, offset.phrase.length])

  const commands: Array<Command<D>> = generateCommands(offsetsWithLinks)
  return (
    <div className={className} {...props}>
      {annotateParse(text, commands, children)}
    </div>
  )
}
