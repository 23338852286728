import * as React from 'react'
import { Button, type ButtonProps, cn } from '@strise/midgard'
import styled, { keyframes } from 'styled-components'

const buttonCountdownAnimation = keyframes`
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
`

const StyledButtonCountdown = styled(Button)<{ countdown: number }>`
  position: relative;
  z-index: 2;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation-name: ${buttonCountdownAnimation};
    animation-timing-function: linear;
    animation-duration: ${(props) => props.countdown};
  }
`

export const ButtonCountdown: React.FC<
  {
    countdown: number
  } & Omit<ButtonProps, 'as'>
> = ({ className, countdown, ...props }) => {
  return (
    <StyledButtonCountdown
      className={cn('bg-secondary-shade-70', className)}
      palette='secondary'
      variant='ghost'
      countdown={countdown}
      {...props}
    />
  )
}
