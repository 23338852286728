import * as React from 'react'
import { Link } from '@strise/midgard'
import { type AnnotationRenderFn, type Command } from './annotateUtils'
import { AnnotateFixNewLine } from './AnnotateFixNewLine'

export const annotateParse = <D extends object>(
  s: string,
  commands: Array<Command<D>>,
  render: AnnotationRenderFn<D>
): React.ReactNode[] => {
  const { res } = commands.reduce(
    (
      acc: { res: React.ReactNode[]; s: string },
      command: Command<D>,
      i: number
    ): { res: React.ReactNode[]; s: string } => {
      switch (command.kind) {
        case 'end': {
          return {
            res: [
              ...acc.res,
              <span key={i}>
                <AnnotateFixNewLine s={acc.s} />
              </span>
            ],

            s: ''
          }
        }
        case 'replace': {
          const s1 = acc.s.substring(0, command.length)
          const s2 = acc.s.substring(command.length)

          return {
            res: [
              ...acc.res,
              React.createElement(render, {
                key: `${i}-${s}`,
                element: <AnnotateFixNewLine s={s1} />,
                offset: command.offset,
                data: command.offset.data,
                index: i
              })
            ],

            s: s2
          }
        }
        case 'linkify': {
          const l1 = acc.s.substring(0, command.length)
          const l2 = acc.s.substring(command.length)
          return {
            res: [
              ...acc.res,
              <Link key={i} href={l1} target='_blank' rel='noopener noreferrer'>
                <AnnotateFixNewLine s={l1} />
              </Link>
            ],

            s: l2
          }
        }
        case 'jump': {
          const j1 = acc.s.substring(0, command.length)
          const j2 = acc.s.substring(command.length)
          return {
            res: [
              ...acc.res,
              <span key={i}>
                <AnnotateFixNewLine s={j1} />
              </span>
            ],

            s: j2
          }
        }
        default: {
          throw new Error('Nope. plz')
        }
      }
    },
    { res: [], s }
  )

  return res
}
