import { EntityIdSearchInput } from '@components/Entity/EntitySearchInput'
import { StepContent } from '@components/Ownerships/edit-owner/StepInfo'
import { EntityLocationFilterKind } from '@components/Search/searchUtils'
import { useEntityMetaQuery } from '@graphqlOperations'
import { t, Trans } from '@lingui/macro'
import { setChildStateFromPath } from '@strise/europa'
import { type BaseEntityLikeFragment } from '@strise/europa/src/graphqlTypes'
import { Button, TextArea, Typography } from '@strise/midgard'
import { type SetStateFn } from '@strise/react-utils'
import { ConfirmDialog, Dialog } from '@strise/system'
import { ApplicationSearchReturnType } from '@strise/types'
import { TestIDs } from '@utils/testIDs'
import * as React from 'react'

export interface EntityCounterpartyFormData {
  comment: string
  counterpartyEntity: string | null
  entity: string | null
  id: string
}

enum AddCounterparty {
  Search = 'Search',
  AddComment = 'Add comment'
}

export const DeleteCounterpartyDialog = ({
  counterpartyEntityId,
  handleCancel,
  handleDeletion,
  loading
}: {
  counterpartyEntityId: string
  handleCancel: () => void
  handleDeletion: () => void
  loading: boolean
}) => {
  const { data } = useEntityMetaQuery({
    variables: {
      id: counterpartyEntityId
    }
  })

  return (
    <ConfirmDialog
      isOpen
      loading={loading}
      onConfirm={handleDeletion}
      danger
      onClose={handleCancel}
      onCancel={handleCancel}
      cancelButtonProps={{
        'data-track': 'Edit Counterparty / Delete Counterparty / Cancel'
      }}
      confirmButtonProps={{
        'data-track': 'Edit Counterparty / Delete counterparty / Confirm',
        'data-id': TestIDs.SidePanel.Counterparty.deleteCounterpartyConfirmButton
      }}
      contentMaxWidth={600}
      title={t`Are you sure you want to remove ${data?.entity.name} as counterparty?`}
      confirmText={t`Delete`}
      cancelText={t`Cancel`}
    />
  )
}

export const CounterpartyCommentInput = ({
  formData,
  handleOnDelete,
  setFormData
}: {
  formData: EntityCounterpartyFormData
  handleOnDelete: () => void
  setFormData: SetStateFn<EntityCounterpartyFormData>
}) => {
  const handleChange = (key: keyof EntityCounterpartyFormData) => (value: string) => {
    setFormData((prevFormData) => ({ ...prevFormData, [key]: value }))
  }

  return (
    <div>
      <TextArea
        className='mt-2'
        onChange={(e) => handleChange('comment')(e.currentTarget.value)}
        value={formData.comment}
        placeholder={t`Comment`}
      />
      {formData.id && (
        <Button
          data-track='Edit Counterparty / Remove Counterparty'
          onClick={handleOnDelete}
          variant='ghost'
          palette='danger'
          data-id={TestIDs.SidePanel.Counterparty.deleteCounterpartyButton}
        >
          <Trans>Remove counterparty</Trans>
        </Button>
      )}
    </div>
  )
}

export const AddEditEntityCounterpartyDialog = ({
  entity,
  formData,
  handleConfirm,
  loading,
  onClose,
  onCounterpartyDelete,
  setFormData
}: {
  entity: BaseEntityLikeFragment
  formData: EntityCounterpartyFormData
  handleConfirm: () => void
  loading: boolean
  onClose: () => void
  onCounterpartyDelete: () => void
  setFormData: SetStateFn<EntityCounterpartyFormData>
}) => {
  const [currentStep, setCurrentStep] = React.useState<AddCounterparty | null>(AddCounterparty.Search)

  const stepInfos = [
    {
      step: AddCounterparty.Search,
      title: (
        <EntityIdSearchInput
          variant='outlined'
          entityKindFilter={ApplicationSearchReturnType.Company}
          entityLocationFilters={[EntityLocationFilterKind.ALL]}
          selectedEntity={formData.counterpartyEntity ?? null}
          setSelectedEntity={setChildStateFromPath(setFormData, 'counterpartyEntity')}
          dataTrack='Add Counterparty / Search Entity'
          data-id={TestIDs.SidePanel.Counterparty.searchEntityTrigger}
          itemsWrapperProps={{ 'data-id': TestIDs.SidePanel.Counterparty.searchEntityResults }}
          inputProps={{ 'data-id': TestIDs.SidePanel.Counterparty.searchEntityInput }}
        />
      ),
      isCompleted: !!formData.counterpartyEntity
    },
    {
      step: AddCounterparty.AddComment,
      title: <Typography variant='aLabelBold'>Add Comment</Typography>,
      content: (
        <CounterpartyCommentInput formData={formData} setFormData={setFormData} handleOnDelete={onCounterpartyDelete} />
      ),
      isCompleted: !!formData.comment
    }
  ]

  const content = (
    <div>
      {stepInfos.map((stepInfo, index) => {
        return (
          <StepContent
            key={index}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            stepInfo={stepInfo}
            previousStepInfo={stepInfos[index - 1]}
            index={index}
          />
        )
      })}
    </div>
  )

  return (
    <Dialog
      title={t`Add as counterparty to entity ${entity.name ?? t`Unknown`}`}
      isOpen
      contentMaxWidth={800}
      body={content}
      onClose={onClose}
      containerProps={{ bgcolor: 'background.default' }}
      contentProps={{ bgcolor: 'background.default' }}
    >
      <div className='flex w-full bg-background-default'>
        <Button
          variant='ghost'
          color='primary'
          onClick={onClose}
          data-track='Add/edit counterparty / Cancel'
          className='w-1/2'
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          className='w-1/2'
          variant='contained'
          palette='primary'
          data-track='Add/edit counterparty / Confirm'
          loading={loading}
          onClick={handleConfirm}
          data-id={TestIDs.SidePanel.Counterparty.addCounterpartyConfirmButton}
        >
          <Trans>Save and close</Trans>
        </Button>
      </div>
    </Dialog>
  )
}
