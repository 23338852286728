import { setChildState, useRecursiveQueryParamsReactRouter, validateArray, validateString } from '@strise/europa'
import { eventEntityIdQueryParamKey, eventIdQueryParamKey, eventMentionsQueryParamKey } from '@strise/strise-utils'

interface EventDialogQueryParamsState {
  [eventEntityIdQueryParamKey]: string | null
  [eventIdQueryParamKey]: string | null
  [eventMentionsQueryParamKey]: string[] | null
}

const defaultState = {
  [eventEntityIdQueryParamKey]: null,
  [eventIdQueryParamKey]: null,
  [eventMentionsQueryParamKey]: null
}

const validations = {
  [eventEntityIdQueryParamKey]: validateString,
  [eventIdQueryParamKey]: validateString,
  [eventMentionsQueryParamKey]: validateArray(validateString)
}

const serializations = {
  [eventEntityIdQueryParamKey]: (value: EventDialogQueryParamsState[typeof eventEntityIdQueryParamKey]) =>
    value ?? undefined,
  [eventIdQueryParamKey]: (value: EventDialogQueryParamsState[typeof eventIdQueryParamKey]) => value ?? undefined,
  [eventMentionsQueryParamKey]: (values: EventDialogQueryParamsState[typeof eventMentionsQueryParamKey]) => {
    if (Array.isArray(values)) {
      return values.map(String)
    }
  }
}

export const useEventDialog = () => {
  const [eventDialogState, setEventDialogState] = useRecursiveQueryParamsReactRouter<EventDialogQueryParamsState>(
    defaultState,
    validations,
    serializations
  )

  const eventId = eventDialogState[eventIdQueryParamKey]
  const entityId = eventDialogState[eventEntityIdQueryParamKey]
  const mentions = eventDialogState[eventMentionsQueryParamKey]

  const setEntityId = setChildState(setEventDialogState, eventEntityIdQueryParamKey)
  const setEventId = setChildState(setEventDialogState, eventIdQueryParamKey)
  const setMentions = setChildState(setEventDialogState, eventMentionsQueryParamKey)

  const reset = () => {
    setEntityId(null)
    setEventId(null)
    setMentions(null)
  }

  return { eventId, entityId, mentions, reset }
}
