import type * as React from 'react'

export interface Offset<D> {
  data?: D
  link?: boolean
  offset: number
  phrase: string
}

export type Command<D> =
  | { kind: 'jump'; length: number }
  | { kind: 'replace'; length: number; offset: Offset<D>; phrase: string }
  | { kind: 'linkify'; length: number }
  | { kind: 'end' }

/**
 * Generate commands on non-overlapping offsets, sorted by offset property.
 */

export interface AnnotationProps<D> {
  data?: D
  element: React.ReactElement
  index: number
  offset: Offset<D>
}

export type AnnotationRenderFn<D> = (arg: AnnotationProps<D>) => React.ReactNode

export const generateCommands = <D extends object>(
  offsets: Array<Offset<D>>,
  pointer: number = 0
): Array<Command<D>> => {
  const [currentOffset, ...rest] = offsets
  if (!currentOffset) {
    return [{ kind: 'end' }]
  }
  const { offset, phrase } = currentOffset
  const { length } = phrase
  const start = offset
  if (start < pointer) {
    // Missed Offset. Skipping
    return generateCommands<D>(rest, pointer)
  }
  if (start > pointer) {
    // Hasn't happened yet. Jumping
    return [{ kind: 'jump', length: start - pointer }, ...generateCommands<D>(offsets, offset)]
  }

  if (currentOffset.link) {
    return [{ kind: 'linkify', length }, ...generateCommands<D>(rest, pointer + length)]
  }
  return [{ kind: 'replace', phrase, length, offset: currentOffset }, ...generateCommands<D>(rest, pointer + length)]
}
