import { Skeleton } from '@strise/midgard'
import * as React from 'react'
import { ModalHeader } from '@strise/system'

export const EventDialogHeaderLoader = () => (
  <ModalHeader>
    <div className='flex items-center'>
      <Skeleton className='mr-4 size-[25px] bg-[calc(rgba(255,255,255,0.30))]' variant='rect' />
      <Skeleton className='h-[15px] w-[30px] bg-[calc(rgba(255,255,255,0.30))]' />
    </div>
  </ModalHeader>
)

export const EventDialogMetadataLoader = () => (
  <div className='pt-6 legacy-xs:w-full legacy-md:w-4/12 legacy-lg:w-3/12'>
    <Skeleton className='mb-2 h-1.5 w-32' />
    <Skeleton className='mb-2 h-1.5 w-28' />
    <Skeleton className='mb-2 h-1.5 w-24' />
    <Skeleton className='mb-2 h-1.5 w-32' />
    <Skeleton className='mb-2 h-1.5 w-28' />
    <Skeleton className='mb-2 h-1.5 w-24' />
  </div>
)

export const EventAiSummaryLoader = () => (
  <div className='mb-10'>
    <div>
      <Skeleton className='mb-1 h-1.5 w-32' />
      <Skeleton className='mb-1 h-1.5 w-32' />
      <Skeleton className='mb-1 h-1.5 w-32' />
      <Skeleton className='mb-1 h-1.5 w-32' />
      <Skeleton className='mb-1 h-1.5 w-32' />
    </div>
  </div>
)

export const EventDialogBodyLoader = () => (
  <div className='legacy-xs:w-full legacy-xs:px-0 legacy-md:w-8/12 legacy-md:px-6 legacy-lg:w-9/12 legacy-lg:px-[3.75rem]'>
    <div className='max-w-[600px]'>
      <Skeleton className='mb-2 h-1.5 w-full' />
      <Skeleton className='mb-2 h-1.5 w-4/5' />
      <Skeleton className='mb-2 h-1.5 w-[90%]' />
      <Skeleton className='mb-2 h-1.5 w-[70%]' />
      <Skeleton className='mb-2 h-1.5 w-full' />
      <Skeleton className='mb-2 h-1.5 w-3/4' />
      <Skeleton className='mb-2 h-1.5 w-[85%]' />
      <Skeleton className='mb-2 h-1.5 w-3/4' />
      <Skeleton className='mb-2 h-1.5 w-[65%]' />
      <Skeleton className='mb-2 h-1.5 w-[85%]' />
      <Skeleton className='mb-2 h-1.5 w-3/5' />
    </div>
  </div>
)
