import * as React from 'react'
import { useReactiveVar } from '@apollo/client'
import { financialFieldsMap } from '@state'
import { ContentLanguage, Currency } from '@strise/types'

export const useFinancialFieldMatching = (financialFieldId: string) => {
  const financialsFieldMapState = useReactiveVar(financialFieldsMap)
  const selectedFinancialField = financialsFieldMapState[financialFieldId]

  return React.useMemo(() => {
    if (!selectedFinancialField) {
      return { matchingCountries: [], name: '' }
    }

    const matchingFinacialFieldCountries = selectedFinancialField.matchingInternationalFields.map((f) => f.country)
    const countries = [selectedFinancialField.country, ...matchingFinacialFieldCountries]

    return { matchingCountries: countries, name: selectedFinancialField.name }
  }, [financialFieldId])
}

export const languageToCurrencyMap: { [key in ContentLanguage]?: Currency } = {
  [ContentLanguage.Norwegian]: Currency.Nok,
  [ContentLanguage.Swedish]: Currency.Sek,
  [ContentLanguage.Danish]: Currency.Dkk,
  [ContentLanguage.English]: Currency.Gbp,
  [ContentLanguage.French]: Currency.Eur,
  [ContentLanguage.German]: Currency.Eur,
  [ContentLanguage.Finnish]: Currency.Eur
}

export const useFinancialFieldCurrencyType = (financialFieldId: string) => {
  const { matchingCountries } = useFinancialFieldMatching(financialFieldId)

  return React.useMemo(() => {
    const currencies = matchingCountries.map((country) => languageToCurrencyMap[country])
    return currencies.filter(Boolean).join('/')
  }, [matchingCountries])
}
