import * as React from 'react'

export const AnnotateFixNewLine = ({ s }: { s: string }) => {
  const newLineIndex = s.indexOf('\n')
  if (newLineIndex < 0) {
    return <>{s}</>
  }
  const before = s.substring(0, newLineIndex)
  const after = s.substring(newLineIndex + 1)
  return (
    <>
      {before}
      <br />
      <AnnotateFixNewLine s={after} />
    </>
  )
}
