import { DisplayLanguage } from '@strise/types'
import * as React from 'react'
import { CurrentUserSettingsContext } from '../CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useApolloClient } from '@apollo/client'
import { toast, useContext, useUserId } from '@strise/europa'
import { t } from '@lingui/macro'
import { useUpdateUserSettingsMutation } from '@graphqlOperations'

const DEFAULT_LANGUAGE = DisplayLanguage.English

export const languageToISO: { [key in DisplayLanguage]?: string } = {
  [DisplayLanguage.Norwegian]: 'no',
  [DisplayLanguage.English]: 'en',
  [DisplayLanguage.Swedish]: 'sv',
  [DisplayLanguage.Danish]: 'da'
}
export const languageToLocaleStr: { [key in DisplayLanguage]?: string } = {
  [DisplayLanguage.Norwegian]: 'nb',
  [DisplayLanguage.English]: 'en',
  [DisplayLanguage.Swedish]: 'sv',
  [DisplayLanguage.Danish]: 'da'
}

export const useDisplayLanguage = (): DisplayLanguage => {
  const userSettings = useContext(CurrentUserSettingsContext)
  const displayLanguage = userSettings.settings.displayLanguage || DEFAULT_LANGUAGE

  return displayLanguage
}

export const useUpdateDisplayLanguage = (): [
  DisplayLanguage,
  (lang: DisplayLanguage, onCompleted?: () => void) => void,
  boolean
] => {
  const apolloClient = useApolloClient()
  const currentUserId = useUserId()
  const displayLanguage = useDisplayLanguage()

  const handleCompleted = () => {
    apolloClient.resetStore()
    toast.success(t`Language updated`)
  }
  const [update, { loading }] = useUpdateUserSettingsMutation({
    onCompleted: handleCompleted
  })

  const handleUpdate = React.useCallback((newLang: DisplayLanguage, onCompleted?: () => void) => {
    update({
      variables: {
        user: currentUserId,
        input: { application: { displayLanguage: newLang } }
      }
    }).then(() => {
      if (onCompleted) onCompleted()
    })
  }, [])

  return [displayLanguage, handleUpdate, loading]
}
