import * as React from 'react'
import { Skeleton } from '@strise/midgard'

export const SkeletonLoader = () => {
  return (
    <div className='grid min-h-[600px] grid-cols-[minmax(0,1fr)_3fr] bg-white'>
      <div className='border-r border-gray-20 bg-gray-10 p-4'>
        <Skeleton className='mb-2 h-6' />
        <div className='flex h-full flex-col gap-3'>
          <Skeleton className='h-9' />
          <Skeleton className='h-9' />
          <Skeleton className='h-9' />
          <Skeleton className='h-9' />
        </div>
      </div>
      <div className='flex h-full flex-col'>
        <div className='px-4 pb-2 pt-6'>
          <Skeleton className='h-6' />
        </div>
        <div className='grid h-full grid-cols-2 gap-6 p-4'>
          <div className='space-y-4'>
            <Skeleton className='h-6' />
            <Skeleton className='h-32' />
            <Skeleton className='h-16' />
          </div>
          <div className='space-y-4'>
            <Skeleton className='h-6' />
            <Skeleton className='h-32' />
            <Skeleton className='h-16' />
          </div>
        </div>
        <div className='bg-gray-10 p-4'>
          <Skeleton className='h-10' />
        </div>
      </div>
    </div>
  )
}
