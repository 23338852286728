import { SystemProvider } from '@strise/system'
import * as React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AuthInit } from '@components/Auth'
import { themeBreakpoints } from './theme'
import { useLocalStorageVersion } from '@utils/hooks'
import { ErrorView } from '@views/ErrorView'
import { Authorize, EuropaContextProvider, Toaster, UserRoleEnum } from '@strise/europa'
import { IdentifyServices } from '@components/IdentifyServices'
import * as Sentry from '@sentry/react'
import { spoof } from '@utils/spoof'
import { Routes } from './Routes'
import { ApolloClientContextProvider } from './apolloClient/ApolloClientContextProvider'
import { DisplayLanguageContextProvider } from '@contexts/DisplayLanguageContext/DisplayLanguageContextProvider'
import { useFetchUserFinancialFields, useSaveUserTimeZone } from '@utils/userHooks'
import { EventLink } from '@components/EventLink'
import { fixGoogleTranslateBug } from '@utils/googleTranslate'
import { useDisplayLanguage } from '@contexts/DisplayLanguageContext/displayLanguageContextUtils'
import { CurrentUserSettingsContextProvider } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContextProvider'
import { TeamContextProvider } from '@contexts/TeamContext/TeamContextProvider'
import { PreLoaderContextProvider } from '@contexts/PreLoaderContext/PreLoaderContextProvider'
import { PreLoaderContextStop } from '@contexts/PreLoaderContext/PreLoaderContextStop'
import { useNavigateToSetup } from '@views/Setup/setupUtils'
import { EntityLink } from '@components/EntityLink/EntityLink'
import { EntityIdLink } from '@components/EntityLink/EntityIdLink'
import { SettingsModalContextProvider } from '@views/Settings/SettingsModalContextProvider'
import 'react-datepicker/dist/react-datepicker.css'
import { MidgardProvider } from '@strise/midgard'
import { RecentlyVisitedEntitiesContextProvider } from '@contexts/RecentlyVisitedEntitiesContext/RecentlyVisitedEntitiesContextProvider'
import { IdentifyErrorService } from '@components/IdentifyErrorService'

export const InitEuropa = ({ children }: { children: React.ReactNode }) => {
  const language = useDisplayLanguage()

  const value = React.useMemo(
    () => ({
      EntityLink,
      EntityIdLink,
      language,
      EventLink
    }),
    [language]
  )

  return <EuropaContextProvider value={value}>{children}</EuropaContextProvider>
}

const InitUser = ({ children }: { children: React.ReactNode }) => {
  useSaveUserTimeZone()
  useNavigateToSetup()
  const loadingFinFields = useFetchUserFinancialFields()

  if (loadingFinFields) return null

  return <>{children}</>
}

const Init = ({ children }: { children: React.ReactNode }) => (
  <PreLoaderContextProvider>
    <AuthInit>
      <IdentifyErrorService>
        <ApolloClientContextProvider>
          <Authorize minRequiredRole={UserRoleEnum.TeamMember}>
            <MidgardProvider>
              <TeamContextProvider>
                <RecentlyVisitedEntitiesContextProvider>
                  <CurrentUserSettingsContextProvider>
                    <SettingsModalContextProvider>
                      <InitUser>
                        <DisplayLanguageContextProvider>
                          <PreLoaderContextStop>{children}</PreLoaderContextStop>
                        </DisplayLanguageContextProvider>
                      </InitUser>
                    </SettingsModalContextProvider>
                  </CurrentUserSettingsContextProvider>
                </RecentlyVisitedEntitiesContextProvider>
              </TeamContextProvider>
            </MidgardProvider>
          </Authorize>
        </ApolloClientContextProvider>
      </IdentifyErrorService>
    </AuthInit>
  </PreLoaderContextProvider>
)

export const App = () => {
  useLocalStorageVersion('0.5')
  fixGoogleTranslateBug()

  spoof.init()

  return (
    <BrowserRouter>
      <SystemProvider themeOverrides={themeBreakpoints}>
        <Sentry.ErrorBoundary fallback={<ErrorView />}>
          <Init>
            <InitEuropa>
              <Toaster />
              <IdentifyServices>
                <Routes />
              </IdentifyServices>
            </InitEuropa>
          </Init>
        </Sentry.ErrorBoundary>
      </SystemProvider>
    </BrowserRouter>
  )
}
