import { type TableFragment, type TableRowFragment, type TableRowValueFragment } from '@graphqlTypes'
import { type ApolloClient } from '@apollo/client'
import { refreshOwnershipIdState } from '@state'

const updateRowValueCache = (
  apolloClient: ApolloClient<unknown>,
  entity: { __typename: string; id: string },
  value: TableRowValueFragment
) => {
  if (value.__typename === 'TableRowValueCreditReport') {
    apolloClient.cache.modify({
      id: `${entity.__typename}:${entity.id}`,
      fields: {
        creditReport: () => value.creditReport
      }
    })
  }

  if (value.__typename === 'TableRowValuePepsWithEntity' && value.maybeEntity) {
    apolloClient.cache.modify({
      id: `${value.maybeEntity.__typename}:${value.maybeEntity.id}`,
      fields: {
        storedPepInfo: () => value.pepInfo
      }
    })
  }
}

const updateRowCache = (
  apolloClient: ApolloClient<unknown>,
  entity: { __typename: string; id: string },
  row: TableRowFragment
) => {
  const values = row.sections.flatMap((section) => section.values)
  values.forEach((value) => updateRowValueCache(apolloClient, entity, value))
}

export const updateTableCache = (
  apolloClient: ApolloClient<unknown>,
  entity: { __typename: string; id: string },
  table: TableFragment
) => {
  // Refresh ownership chart in sidepanel in case Review fetched new global owners
  refreshOwnershipIdState(entity.id)

  table.rows.forEach((row) => updateRowCache(apolloClient, entity, row))
}
