import * as React from 'react'
import {
  type AssigneeCompanyEdgeFragment,
  type CompanyUserConnectionEdgeFragment,
  type ListViewCompanyFragment,
  type SimpleUserFragment
} from '@graphqlTypes'
import { useUserId } from '@strise/europa'
import { t } from '@lingui/macro'
import { type ApolloCache } from '@apollo/client'
import { useTeam } from '@contexts/TeamContext/TeamContext'
import { refreshReviewState } from '@state'
import { useTeamUsers } from '@utils/teamUsers'
import {
  useAssignUsersToCompaniesMutation,
  useReassignUsersToCompaniesMutation,
  useUnassignUsersFromCompaniesMutation
} from '@graphqlOperations'
import { type AssigneeEdge } from '@utils/assigneeUtils'

export const useYou = (userEdges: Array<{ node: SimpleUserFragment }>) => {
  const currentUserId = useUserId()

  return React.useMemo(
    () =>
      userEdges.map((userEdge) => ({
        ...userEdge,
        node: {
          ...userEdge.node,
          name:
            currentUserId === userEdge.node.id
              ? `${userEdge.node.name} (${t({ id: 'you (deg)', message: 'you' })})`
              : userEdge.node.name
        }
      })),
    [userEdges]
  )
}

export const useIsCompanyAssignee = (assignees: AssigneeEdge[], assigneeId: string | undefined) => {
  return React.useMemo(() => {
    if (!assigneeId) return false

    return assignees.some(({ node }) => node.id === assigneeId)
  }, [assigneeId, assignees])
}

const updateCache = (cache: ApolloCache<unknown>, companies: AssigneeCompanyEdgeFragment[] | undefined) => {
  if (!companies) return

  companies.forEach(({ node: company }) => {
    cache.modify({
      id: `SimpleCompany:${company.id}`,
      fields: {
        // @ts-expect-error
        assignee: (assignees: ListViewCompanyFragment['assignee'], { toReference }) => ({
          ...assignees,
          edges: company.assignee.edges.map(({ node }) => ({
            __typename: 'SimpleUserConnectionEdge',
            node: toReference(node)
          }))
        })
      }
    })
  })
}

export const useReassignUsersToCompanies = (onCompleted?: () => void) => {
  const handleCompleted = () => {
    refreshReviewState(refreshReviewState() + 1)
    if (onCompleted) onCompleted()
  }

  const [reassign, { loading }] = useReassignUsersToCompaniesMutation({ onCompleted: handleCompleted })

  const reassignUser = React.useCallback(async (companies: string[], assignees: string[]) => {
    await reassign({
      variables: { users: assignees, companies },
      update: (cache, { data: updateData }) =>
        updateCache(cache, updateData?.reassignUsersToCompanies.team.portfolio?.companies.edges)
    })
  }, [])

  return { reassignUser, loading }
}

export const useAssigneeMutations = (onCompleted?: () => void) => {
  const team = useTeam()
  const { id: teamId, portfolioId } = team
  const { teamUsers } = useTeamUsers()

  const handleCompleted = () => {
    if (onCompleted) onCompleted()
  }

  const [assign, { loading: assignLoading }] = useAssignUsersToCompaniesMutation({ onCompleted: handleCompleted })

  const [unassign, { loading: unassignLoading }] = useUnassignUsersFromCompaniesMutation({
    update: (cache, { data: updateData }) =>
      updateCache(cache, updateData?.unassignUsersFromCompanies.team.portfolio?.companies.edges),
    onCompleted: handleCompleted
  })

  const assignUser = React.useCallback(
    async ({ assignees, companies, users }: { assignees: AssigneeEdge[]; companies: string[]; users: string[] }) => {
      const optimisticEdges: CompanyUserConnectionEdgeFragment[] = assignees.map(
        (assignee) =>
          ({
            __typename: 'CompanyUserConnectionEdge',
            node: assignee.node
          }) as const
      )

      const addedUser = teamUsers.find(({ node }) => users.includes(node.id))

      const optimisticEdgesWithAddedUser: CompanyUserConnectionEdgeFragment[] = addedUser
        ? [...optimisticEdges, { __typename: 'CompanyUserConnectionEdge', node: addedUser.node }]
        : optimisticEdges

      const optimisticCompanyId = companies[0]

      await assign({
        variables: { users, companies },
        update: (cache, { data: updateData }) => {
          updateCache(cache, updateData?.assignUsersToCompanies.team.portfolio?.companies.edges)
        },
        optimisticResponse: optimisticCompanyId
          ? {
              __typename: 'Mutation',
              assignUsersToCompanies: {
                team: {
                  id: teamId,
                  portfolio: {
                    id: portfolioId,
                    companies: {
                      edges: [
                        {
                          node: {
                            id: optimisticCompanyId,
                            assignee: {
                              edges: optimisticEdgesWithAddedUser,
                              __typename: 'CompanyUserConnection'
                            },
                            __typename: 'Company'
                          },
                          __typename: 'PortfolioCompanyConnectionEdge'
                        }
                      ],
                      __typename: 'PortfolioCompanyConnection'
                    },
                    __typename: 'Portfolio'
                  },
                  __typename: 'Team'
                },
                __typename: 'MutationQuery'
              }
            }
          : undefined
      })
    },
    []
  )

  const unassignUser = React.useCallback(
    async ({ assignees, companies, users }: { assignees: AssigneeEdge[]; companies: string[]; users: string[] }) => {
      const optimisticEdges = assignees
        .filter(({ node }) => !users.includes(node.id))
        .map(
          (assignee) =>
            ({
              __typename: 'CompanyUserConnectionEdge',
              node: assignee.node
            }) as const
        )

      const optimisticCompanyId = companies[0]

      await unassign({
        variables: { users, companies },
        optimisticResponse: optimisticCompanyId
          ? {
              __typename: 'Mutation',
              unassignUsersFromCompanies: {
                team: {
                  id: teamId,
                  portfolio: {
                    id: portfolioId,
                    companies: {
                      edges: [
                        {
                          node: {
                            id: optimisticCompanyId,
                            assignee: {
                              edges: optimisticEdges,
                              __typename: 'CompanyUserConnection'
                            },
                            __typename: 'Company'
                          },
                          __typename: 'PortfolioCompanyConnectionEdge'
                        }
                      ],
                      __typename: 'PortfolioCompanyConnection'
                    },
                    __typename: 'Portfolio'
                  },
                  __typename: 'Team'
                },
                __typename: 'MutationQuery'
              }
            }
          : undefined
      })
    },
    []
  )

  return {
    assign: assignUser,
    unassign: unassignUser,
    assignLoading,
    unassignLoading
  }
}
