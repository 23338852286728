import { Combobox, type ComboboxItem, type ComboboxProps, IconTagAdd } from '@strise/midgard'
import { useLazyQuery } from '@apollo/client'
import { useTeam } from '@contexts/TeamContext/TeamContext'
import { useAddTagsToCompaniesMutation, useRemoveTagsFromCompaniesMutation } from '@graphqlOperations'
import { type CompanyTagConnectionEdgeFragment, type TagsQuery, type CompanyTagFragment } from '@graphqlTypes'
import { t, Trans } from '@lingui/macro'
import { refreshReviewState } from '@state'
import { toast } from '@strise/europa'
import * as React from 'react'
import TAGS from '../../graphql/tags/queries/tags.graphql'
import { TagActions } from './Tags/TagActions'
import { useExtractTagValue, useRecentlyUsedTags, useSortTags } from './Tags/tagUtils'
import { TestIDs } from '@utils/testIDs'

const sortTags = (a: { node: { name: string } }, b: { node: { name: string } }) =>
  a.node.name.localeCompare(b.node.name)

export const InputCompanyTags: React.FC<
  {
    cacheKeys?: Record<string, string>
    companyIds: string[]
    onAction?: () => void
    tags: CompanyTagConnectionEdgeFragment[]
  } & ComboboxProps<CompanyTagFragment>
> = React.forwardRef(
  ({ cacheKeys, children, className, companyIds, onAction, tags, ...props }, ref: React.Ref<HTMLDivElement>) => {
    const { id: teamId, portfolioId } = useTeam()

    const onCompleted = () => {
      refreshReviewState(refreshReviewState() + 1)
      toast.success(t`Company tags changed`)
    }

    const [addTags, { loading: addTagsLoading }] = useAddTagsToCompaniesMutation({ onCompleted })
    const [removeTags, { loading: removeTagsLoading }] = useRemoveTagsFromCompaniesMutation({ onCompleted })

    const mutationLoading = addTagsLoading || removeTagsLoading

    const [fetchTags, { data, loading }] = useLazyQuery<TagsQuery>(TAGS)
    const tagsEdges = data?.team.tags.edges ?? []
    const sortedTags = useSortTags(tagsEdges, tags)

    const [, setRecentlyUsedTags] = useRecentlyUsedTags()

    const value: Array<ComboboxItem<CompanyTagFragment>> = React.useMemo(
      () =>
        [...tags].sort(sortTags).map(({ node }) => ({
          label: node.name,
          id: node.id,
          value: node
        })),
      [tags]
    )

    const extractTagValue = useExtractTagValue()

    const addHandler = async (tag: ComboboxItem<CompanyTagFragment>) => {
      const tagValue = await extractTagValue(tag)

      if (!tagValue) return

      setRecentlyUsedTags(tagValue.id)

      const variables = { tags: [tagValue.id], companies: companyIds }

      if (!cacheKeys) {
        addTags({ variables })
        return
      }

      const optimisticEdges = companyIds.map((companyId) => ({
        node: {
          id: companyId,
          tags: {
            key: cacheKeys[companyId] ?? '',
            edges: [
              ...tags,
              {
                node: {
                  ...tagValue,
                  __typename: 'Sticker' as const
                },
                __typename: 'CompanyStickerConnectionEdge' as const
              }
            ].sort(sortTags),
            __typename: 'CompanyStickerConnection' as const
          },
          __typename: 'Company' as const
        },
        __typename: 'PortfolioCompanyConnectionEdge' as const
      }))

      await addTags({
        variables,
        optimisticResponse: {
          addStickersToCompanies: {
            team: {
              id: teamId,
              portfolio: {
                id: portfolioId,
                companies: {
                  edges: optimisticEdges,
                  __typename: 'PortfolioCompanyConnection'
                },
                __typename: 'Portfolio'
              },
              __typename: 'Team'
            },
            __typename: 'MutationQuery'
          },
          __typename: 'Mutation'
        }
      })
      if (onAction) onAction()
    }

    const removeHandler = async (tag: ComboboxItem<CompanyTagFragment>) => {
      const variables = { tags: [tag.id], companies: companyIds }

      if (!cacheKeys) {
        await removeTags({ variables })
        return
      }

      const optimisticEdges = companyIds.map((companyId) => ({
        node: {
          id: companyId,
          tags: {
            key: cacheKeys[companyId] ?? '',
            edges: tags.filter(({ node }) => node.id !== tag.id),
            __typename: 'CompanyStickerConnection' as const
          },
          __typename: 'Company' as const
        },
        __typename: 'PortfolioCompanyConnectionEdge' as const
      }))

      await removeTags({
        variables,
        optimisticResponse: {
          removeStickersFromCompanies: {
            team: {
              id: teamId,
              portfolio: {
                id: portfolioId,
                companies: {
                  edges: optimisticEdges,
                  __typename: 'PortfolioCompanyConnection'
                },
                __typename: 'Portfolio'
              },
              __typename: 'Team'
            },
            __typename: 'MutationQuery'
          },
          __typename: 'Mutation'
        }
      })
      if (onAction) onAction()
    }

    const options: Array<ComboboxItem<CompanyTagFragment>> = sortedTags.map(({ node }) => {
      const actions = (setOpen: React.Dispatch<React.SetStateAction<boolean>>) => (
        <TagActions tag={node} setOpen={setOpen} />
      )
      return {
        label: node.name,
        id: node.id,
        value: node,
        actions
      }
    })

    const handleOpen = async () => {
      await fetchTags()
      if (onAction) onAction()
    }

    return (
      <Combobox
        actionButtonProps={{ 'data-id': TestIDs.Common.Tags.actions }}
        startIcon={<IconTagAdd size='md' className='mr-2 shrink-0' />}
        value={value}
        items={options}
        inlineSearch
        showItemsAsChips
        enableCreate
        loading={loading || mutationLoading}
        className={className}
        onOpen={handleOpen}
        onAdd={addHandler}
        onRemove={removeHandler}
        data-track='Company / Tags'
        data-id='edit-company-tags'
        ref={ref}
        maxLabelLengthProps={{
          maxLabelLength: 12,
          maxTotalLabelLength: 28
        }}
        aria-label={t`Click to add tags`}
        {...props}
      >
        {children || <Trans>Add tags</Trans>}
      </Combobox>
    )
  }
)
