import * as React from 'react'
import { LoadingView } from '@views/LoadingView'
import { PreLoaderContext } from './PreLoaderContext'

export const PreLoaderContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [loading, setLoading] = React.useState<boolean>(true)

  const value = React.useMemo(
    () => ({
      loading,
      setLoading
    }),
    [loading]
  )

  return (
    <PreLoaderContext.Provider value={value}>
      {loading && <LoadingView />}
      {children}
    </PreLoaderContext.Provider>
  )
}
