import { makeVar } from '@apollo/client'
import { type ApiEnvState, initApiEnv } from '@strise/europa'
import { MenuState } from '@components/Menu/menuUtils'
import { type FinancialFieldsMap } from '@utils/financialTypes'

// Team / portfolio
export const team = makeVar<string | null>(null)
export const portfolio = makeVar<string | null>(null)

// Financials
export const financialFieldsMap = makeVar<FinancialFieldsMap>({})

// API
export const apiEnvState = makeVar<ApiEnvState>(initApiEnv())

// Company
export const lastAddedCompanyState = makeVar<string | null>(null)
export const newCompaniesState = makeVar<string[]>([])

// Review
export const refreshReviewState = makeVar<number>(0)
export const refreshReviewCompanyMap = makeVar<Record<string, number>>({})

// Spoof
export const spoofUser = makeVar<string | null>(null)

// Team activities
export const refreshActivityViewState = makeVar<number>(0)
export const refreshCompanyRemindersState = makeVar<number>(0)

// Ownership
export const refreshOwnershipIdState = makeVar<string | null>(null)

// Menu
export const menuState = makeVar<MenuState>(MenuState.DEFAULT)
