import { onUnhandledRequest } from './onUnhandledRequest'

export const enableMocking = async () => {
  if (process.env.API_MOCK !== 'true') {
    return
  }

  const { worker } = await import('./browser')

  return await worker.start({
    onUnhandledRequest
  })
}
