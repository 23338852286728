import { getBrowserGlobals } from '@strise/system'
import { saveAs } from 'file-saver'

export enum ContentType {
  PNG = 'png',
  JPG = 'jpg',
  PDF = 'pdf',
  CSV = 'csv'
}

const contentTypeToMimeType: { [key in ContentType]: string } = {
  [ContentType.PNG]: 'image/png',
  [ContentType.JPG]: 'image/jpg',
  [ContentType.PDF]: 'application/pdf',
  [ContentType.CSV]: 'text/csv'
}

const createBlob = (content: Array<string | Uint8Array>, contentType: ContentType) => {
  const browserGlobals = getBrowserGlobals()
  if (browserGlobals) {
    const mimeType = contentTypeToMimeType[contentType]
    return new browserGlobals.window.Blob(content, { type: `${mimeType};charset=utf-8;` })
  }
  return null
}

export const createFile = (blob: Blob | null, name: string, contentType: ContentType) => {
  const browserGlobals = getBrowserGlobals()
  if (browserGlobals && blob) {
    const fileName = name.endsWith(`.${contentType}`) ? name : `${name}.${contentType}`
    const mimeType = contentTypeToMimeType[contentType]
    return new browserGlobals.window.File([blob], fileName, {
      type: mimeType
    })
  }
  return null
}

const base64ToBlob = (base64: string, contentType: ContentType) => {
  const sliceSize = 512

  const browserGlobals = getBrowserGlobals()
  if (browserGlobals) {
    const byteCharacters = browserGlobals.window.atob(base64)

    const chunkCount = Math.ceil(byteCharacters.length / sliceSize)

    const byteArrays = Array.from({ length: chunkCount }, (_, i) => {
      const offset = i * sliceSize
      const slice = byteCharacters.slice(offset, offset + sliceSize)
      const byteNumbers = Array.from(slice, (str) => str.charCodeAt(0))
      return new Uint8Array(byteNumbers)
    })

    return createBlob(byteArrays, contentType)
  }
  return null
}

export const triggerBase64Download = (base64: string, name: string, contentType: ContentType) => {
  const blob = base64ToBlob(base64, contentType)
  const file = createFile(blob, name, contentType)
  file && saveAs(file)
}

export const triggerDownload = (content: string, name: string, contentType: ContentType) => {
  const blob = createBlob([content], contentType)
  const file = createFile(blob, name, contentType)
  file && saveAs(file)
}
