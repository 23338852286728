import type { ConflictFragment, EntityLikeMetaFragment } from '@graphqlTypes'
import { groupBy } from 'lodash-es'
import { ConflictReasonKind, RoleConflictSelectionKind } from '@strise/types'
import { t } from '@lingui/macro'
import type { ApolloCache } from '@apollo/client'

// TODO - introduce similar enum in the backend?
export enum ConflictKind {
  Ownership = 'Ownership',
  Role = 'Role',
  Unknown = 'Unknown'
}

/** Used to avoid nested ternary operators linting errors */
export const getConflictKindDataTrackBaseString = (kind: ConflictKind) => {
  if (kind === ConflictKind.Ownership) return 'Resolve ownership conflicts'
  if (kind === ConflictKind.Role) return 'Resolve role conflicts'
  return 'Resolve unknown conflicts'
}

export interface TransformedConflict {
  conflicts: ConflictFragment[]
  entity: EntityLikeMetaFragment
  id: string
  kind: ConflictKind
}

/** Transforms conflicts into a format where we group ownership conflicts */
export const transformConflicts = (unresolvedConflicts?: ConflictFragment[], entityId?: string | null) => {
  const transformedRoleConflicts: TransformedConflict[] =
    unresolvedConflicts
      ?.filter((conflict) => conflict.__typename === 'RoleConflictV2')
      .map((conflict) => {
        return {
          conflicts: [conflict],
          // Need to cast it as EntityLikeMetaFragment as we don't want to fetch all the fields.
          // We have defined our EntityLikeMetaFragment type a bit too verbose.
          entity: conflict.entity as EntityLikeMetaFragment,
          id: conflict.id,
          kind: ConflictKind.Role
        }
      }) || []

  const ownershipConflicts = unresolvedConflicts?.filter((conflict) => conflict.__typename === 'OwnershipConflict')

  const transformedOwnershipConflicts = (() => {
    // Group all ownership conflicts if entityId is defined
    if (ownershipConflicts && entityId) {
      const ownershipRootEntity = ownershipConflicts.find((conflict) => conflict.entity.id === entityId)?.entity
      if (ownershipRootEntity) {
        return [
          {
            conflicts: ownershipConflicts,
            // Need to cast it as EntityLikeMetaFragment as we don't want to fetch all the fields.
            // We have defined our EntityLikeMetaFragment type a bit too verbose.
            entity: ownershipRootEntity as EntityLikeMetaFragment,
            id: `ownership-conflicts-${entityId}`,
            kind: ConflictKind.Ownership
          }
        ]
      }
    }

    // When entityId is undefined, group ownership conflicts by each entity's ID instead
    return ownershipConflicts && ownershipConflicts.length > 0
      ? Object.entries(groupBy(ownershipConflicts, (conflict) => conflict.entity.id)).map(([entity, conflicts]) => ({
          conflicts,
          // Need to cast it as EntityLikeMetaFragment as we don't want to fetch all the fields.
          // We have defined our EntityLikeMetaFragment type a bit too verbose.
          entity: conflicts[0]?.entity as EntityLikeMetaFragment,
          id: `ownership-conflicts-${entity}`,
          kind: ConflictKind.Ownership
        }))
      : []
  })()

  return [...transformedRoleConflicts, ...transformedOwnershipConflicts]
}

export const getConflictPromptResolveActionText = (selectionKind: RoleConflictSelectionKind | undefined): string => {
  switch (selectionKind) {
    case RoleConflictSelectionKind.Current: {
      return t`Keep edited data`
    }
    case RoleConflictSelectionKind.Conflicting: {
      return t`Archive edited data`
    }
    case RoleConflictSelectionKind.Both: {
      return t`Keep both`
    }
    default: {
      return t`Resolve`
    }
  }
}

export const getConflictPromptBodyText = (conflictKind: ConflictKind, entityName: string): string => {
  switch (conflictKind) {
    case ConflictKind.Ownership: {
      return t`This will affect all owners of ${entityName}.`
    }
    case ConflictKind.Role: {
      return t`This will affect the roles of ${entityName}.`
    }
    default: {
      return t`This will affect ${entityName}.`
    }
  }
}

export const getConflictPromptTitleText = (
  conflictKind: ConflictKind,
  selectionKind: RoleConflictSelectionKind | undefined
): string => {
  const identifier = `${conflictKind}-${selectionKind}`

  switch (identifier) {
    case `${ConflictKind.Ownership}-${RoleConflictSelectionKind.Current}`: {
      return t`Are you sure you want to apply the previously edited data to the ownership chart?`
    }
    case `${ConflictKind.Ownership}-${RoleConflictSelectionKind.Conflicting}`: {
      return t`Are you sure you want to archive the edited data from the ownership chart?`
    }
    case `${ConflictKind.Role}-${RoleConflictSelectionKind.Current}`: {
      return t`Are you sure you want to keep the edited role?`
    }
    case `${ConflictKind.Role}-${RoleConflictSelectionKind.Conflicting}`: {
      return t`Are you sure you want to archive the edited role?`
    }
    case `${ConflictKind.Role}-${RoleConflictSelectionKind.Both}`: {
      return t`Are you sure you want to keep both the edited and the official role?`
    }
    default: {
      return t`Are you sure you want to resolve this conflict?`
    }
  }
}

export const getConflictCheckboxLabelText = (conflictKind: ConflictKind, isCustom: boolean): string => {
  const identifier = `${conflictKind}-${isCustom}`

  switch (identifier) {
    case `${ConflictKind.Ownership}-${Boolean(true)}`: {
      return t`Updated and edited ownership data`
    }
    case `${ConflictKind.Ownership}-${Boolean(false)}`: {
      return t`Updated official ownership data`
    }
    case `${ConflictKind.Role}-${Boolean(true)}`: {
      return t`Edited role data`
    }
    case `${ConflictKind.Role}-${Boolean(false)}`: {
      return t`Updated official role data`
    }
    default: {
      return t`Invalid data`
    }
  }
}

export const getConflictReasonText = (reason: ConflictReasonKind): string => {
  switch (reason) {
    case ConflictReasonKind.Added: {
      return t`This role has been added to the official registry`
    }
    case ConflictReasonKind.Mutated: {
      return t`This role has been updated in the official registry`
    }
    case ConflictReasonKind.Removed: {
      return t`This role has been removed from the official registry`
    }
    default: {
      return t`Unknown`
    }
  }
}

// clear the cache for the ownerChart, meaning it will be refetched next time it's needed
export const resetOwnershipChartCache = (cache: ApolloCache<object>, entityId: string) => {
  cache.modify({
    id: cache.identify({ __typename: 'Company', id: entityId }),
    fields: {
      ownerChart(_, { DELETE }) {
        return DELETE
      }
    }
  })
}

// Will clear the cache for the roleTable, meaning it will be refetched next time it's needed
export const resetRoleTableCache = (cache: ApolloCache<object>, entityId: string) => {
  cache.modify({
    id: cache.identify({ __typename: 'Company', id: entityId }),
    fields: {
      roleTableV2(_, { DELETE }) {
        return DELETE
      }
    }
  })
}
