import { EntityMetaItems } from '@components/EntityMeta/EntityMetaItems'
import { Timespan } from '@components/Timespan/Timespan'
import { type EntityLikeMetaFragment, type RoleConflictPartFragment } from '@graphqlTypes'
import { Trans } from '@lingui/macro'
import { formatDate } from '@strise/europa'
import { Checkbox, cn, Tag, Typography } from '@strise/midgard'
import { TestIDs } from '@utils/testIDs'
import * as React from 'react'
import { Ownerships } from '@components/Ownerships/Ownerships'
import {
  ConflictKind,
  getConflictCheckboxLabelText,
  getConflictKindDataTrackBaseString,
  type TransformedConflict
} from '@components/Conflicts/conflictUtils'
import { ConflictReasonKind, RoleConflictSelectionKind } from '@strise/types'

interface ConflictCardBaseProps {
  above25: boolean
  checked: boolean
  conflict: TransformedConflict
  isCustom?: boolean
  setChecked: (checked: boolean) => void
}
export const ConflictCard = ({ above25, checked, conflict, isCustom, setChecked }: ConflictCardBaseProps) => {
  const firstConflict = conflict.conflicts[0]
  const conflictPart = isCustom ? firstConflict?.customPart : firstConflict?.conflictingPart
  return (
    <div className={cn('border-y border-r border-gray-20 p-4', checked && 'ring-1 ring-inset ring-blue-50')}>
      <Checkbox
        containerProps={{ className: 'flex-row-reverse justify-between space-x-0 mb-4' }}
        labelContainerProps={{ className: 'ml-0' }}
        id={`conflict-part-${isCustom ? 'current' : 'updated'}`}
        checked={checked}
        onCheckedChange={setChecked}
        label={getConflictCheckboxLabelText(conflict.kind, !!isCustom)}
        data-track={`${getConflictKindDataTrackBaseString(conflict.kind)} / ConflictCard / Checkbox`}
        data-id={TestIDs.ConflictsModal.checkbox(
          isCustom ? RoleConflictSelectionKind.Current : RoleConflictSelectionKind.Conflicting
        )}
      />
      {conflict.kind === ConflictKind.Ownership && conflict.entity.__typename === 'Company' && (
        <OwnershipConflictCardContent above25={above25} isCustom={isCustom} entity={conflict.entity} />
      )}
      {conflict.kind === ConflictKind.Role && conflictPart && conflictPart.__typename === 'RoleConflictPart' && (
        <RoleConflictCardContent conflictPart={conflictPart} isCustom={isCustom} reason={firstConflict?.reason} />
      )}
    </div>
  )
}

interface RoleConflictCardContentProps {
  conflictPart: RoleConflictPartFragment
  isCustom?: boolean
  reason?: ConflictReasonKind | null
}

const RoleConflictCardContent = ({ conflictPart, isCustom, reason }: RoleConflictCardContentProps) => {
  return (
    <>
      <div className='mb-4 border border-gray-20 p-4'>
        <div className='flex justify-between'>
          <Typography>{conflictPart.roleMeta?.roleTitle}</Typography>
        </div>
        <Typography>{conflictPart.entity?.name}</Typography>
        {conflictPart.entity && <EntityMetaItems entity={conflictPart.entity} />}
        {conflictPart.roleMeta?.period && (
          <Timespan timespan={conflictPart.roleMeta.period} isActive={conflictPart.roleMeta.isActive} />
        )}
      </div>
      {isCustom && (
        <div className='flex items-baseline'>
          <Typography className='mr-1 inline-block bg-semanticBlue-5 p-1 text-semanticBlue-50'>
            <Trans>Edited</Trans>
          </Typography>
          <Typography>
            <Trans>by</Trans> {conflictPart.roleMeta?.customMeta?.lastModifiedBy.name}
            {', '}
            {conflictPart.roleMeta?.customMeta?.lastModifiedAt &&
              formatDate(conflictPart.roleMeta.customMeta.lastModifiedAt)}
          </Typography>
        </div>
      )}
      {!isCustom && reason && <ReasonText reason={reason} />}
      {isCustom && <Typography>{conflictPart.roleMeta?.customMeta?.comment}</Typography>}
    </>
  )
}

const ReasonText = ({ reason }: { reason: ConflictReasonKind }) => {
  const textContent = () => {
    switch (reason) {
      case ConflictReasonKind.Added: {
        return (
          <Trans>
            This role has been
            <Tag className='mx-1' palette='green'>
              added
            </Tag>
            to the official registry
          </Trans>
        )
      }
      case ConflictReasonKind.Mutated: {
        return (
          <Trans>
            This role has been
            <Tag className='mx-1' palette='orange'>
              updated
            </Tag>
            in the official registry
          </Trans>
        )
      }
      case ConflictReasonKind.Removed: {
        return (
          <Trans>
            This role has been
            <Tag className='mx-1' palette='pink'>
              removed
            </Tag>
            from the official registry
          </Trans>
        )
      }
    }
  }

  return <Typography className='flex'>{textContent()}</Typography>
}

interface OwnershipConflictCardContentProps {
  above25: boolean
  entity: EntityLikeMetaFragment & { __typename: 'Company' }
  isCustom?: boolean
}

const OwnershipConflictCardContent = ({ above25, entity, isCustom }: OwnershipConflictCardContentProps) => {
  return (
    <div className='h-full'>
      <Ownerships
        className='max-h-[65vh] p-4'
        key={`percentage-${String(true)}-full-screen`}
        showOriginal={!isCustom}
        editMode={false}
        above25={above25}
        entity={entity}
        noFlags={false}
        noSubsidiaries
        noSubsidiaryCount={false}
        noStatus={false}
        isExport={false}
        dragOpts={{ sharedXYOnly: true }}
        noLink
      />
    </div>
  )
}
